<template>
  <div :class="['customer-invoices-bankdrafts-container', breakpointClass, 'pa-0']">
    <SpinnerLayerComponent v-if="loading" class="spinner-layer"></SpinnerLayerComponent>
    <template v-else>
      <v-row>
        <v-col cols="12" class="customer-invoices-form">
          <FormGeneratorComponent
            v-if="generatorFormSchema"
            :schema="generatorFormSchema"
            :fieldValues="registerFormData"
            :context="generatorFormContext"
            :permissionsEntity="permissionsEntity"
            validateOnLoad
            @lexonButtonEvent="onAutomaticBankdraft"
            @saveFormData="onChangeGeneratorForm"
            @emit-on-blur-event="
              invoicesMaxNumberFormFieldsLogicMixin.fillTextfieldFields(
                registerFormData,
                selectedInvoiceCompanyInfoCloned
              )
            "
          ></FormGeneratorComponent>
        </v-col>
        <v-col cols="12" class="billing-totals">
          <LfTotalsComponent :items="totals"></LfTotalsComponent>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="grid-container">
          <LfGridComponent
            v-if="gridConfiguration"
            :gridConfiguration="gridConfiguration"
            :itemData="bankdraftList"
            :toolbarOptions="toolbarOptionsCustom"
            :commandClick="onCommandClick"
            :contextMenuItems="contextMenuItems"
            :childGrid="miniGrid"
            @toolbarClicked="onToolbarClicked"
            @rowClick="onRowClicked"
            @contextMenuClicked="onContextMenuClicked"
            :rowDataBound="onRowDataBound"
            :exportName="texts.gridTitle"
          ></LfGridComponent>
          <PromptDialogComponent
            :title="bankdraftPromptTitle"
            :isOpened="bankdraftPromptOpened"
            :context="bankdraftFormContext"
            :formSchema="bankdraftFormSchema"
            :formFieldValues="bankdraftFormData"
            :width="950"
            @execute-action="onBankdraftPromptExecuteAction"
            @changeFormField="onChangeBankdraftForm"
          ></PromptDialogComponent>
          <PromptDialogComponent
            :title="partialbankdraftPromptTitle"
            :isOpened="partialbankdraftPromptOpened"
            :context="partialbankdraftFormContext"
            :formSchema="partialbankdraftFormSchema"
            :formFieldValues="partialbankdraftFormData"
            :width="950"
            @execute-action="onPartialbankdraftPromptExecuteAction"
          ></PromptDialogComponent>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script setup lang="ts">
import { InvoicesFormFields, RenderedForm } from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'
import {
  ActionName as GridActionName,
  ClickEventArgs,
  CommandClickEventArgs,
  ItemModel
} from '@/components/grids/LfGrid/LfGridTypes'
import { Icons } from '@/icons/icons'
import Vue, { computed, ComputedRef, onMounted, ref } from 'vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import { useVuetify } from '@/composables/useVuetify'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import usePartialbankdraftForm from '@/components/billing/BankdraftTab/composables/usePartialbankdraftForm'
import useBankdraftForm from '@/components/billing/BankdraftTab/composables/useBankdraftForm'
import useCustomerInvoiceBankdraft from '@/components/billing/BankdraftTab/composables/useCustomerInvoiceBankdraft'
import useBankdraftGeneratorForm from '@/components/billing/BankdraftTab/composables/useBankdraftGeneratorForm'
import useGridConfiguration from '@/composables/useGridConfiguration'
import usePermissions from '@/composables/usePermissions'
import useDialog from '@/composables/useDialog'
import useFormData from '@/composables/useFormData'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import { useI18n } from 'vue-i18n-composable'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { ContextMenuClickEventArgs, RecordClickEventArgs, RowDataBoundEventArgs } from '@syncfusion/ej2-vue-grids'
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import InvoicesMaxNumberFormFieldsLogicMixin from '@/mixins/InvoicesMaxNumberFormFieldsLogic/InvoicesMaxNumberFormFieldsLogicMixin.vue'
import { TaxesFormFields } from '@/mixins/TaxesDynamicFormFields/types/TaxesDynamicFormFieldsMixinTypes'
import BillingMixin from '@/mixins/BillingMixin.vue'
import { Partialbankdraft } from '@/store/modules/invoices/invoicesTypes'

// TRANSLATIONS
const { t } = useI18n()
const texts = {
  buttons: {
    add: t('action_buttons.add').toString(),
    close: t('action_buttons.close').toString(),
    remove: t('action_buttons.remove').toString(),
    save: t('action_buttons.save').toString(),
    print: t('action_buttons.print').toString(),
    export: t('action_buttons.export').toString(),
    cancel: t('action_buttons.cancel').toString()
  },
  dialogRemoveBankdraft: t('components.customer_invoices_bankdrafts.dialog_remove_detail').toString(),
  dialogRemovePartialbankdraft: t('components.customer_invoices_partialbankdrafts.dialog_remove_detail').toString(),
  contextMenuItems: {
    edit: t('components.context_menu.edit').toString(),
    addPartialbankdraft: t('components.context_menu.add_partialbankdraft').toString(),
    remove: t('components.context_menu.remove').toString()
  },
  promptTitles: {
    newBankdraft: 'Nuevo Cobro',
    editBankdraft: 'Editar Cobro',
    editPartialbankdraft: t('components.customer_invoices_partialbankdrafts.edit_detail').toString(),
    newPartialbankdraft: t('components.customer_invoices_partialbankdrafts.new_detail').toString()
  },
  gridTitle: t('components.customer_invoices_partialbankdrafts.grid_title').toString(),
  noResultsSecondParagraph: t('components.no_results.no_results_second_paragraph').toString(),
  charged: t('components.file_manager.grid_columns.public_ok').toString()
}

// DATA
const loading = ref(true)
const selectedInvoiceCompanyInfoCloned = ref()
const bankdraftId = ref()
const bankdraftFormData = ref()
const partialbankdraftFormData = ref()
const bankdraftPromptOpened = ref(false)
const partialbankdraftPromptOpened = ref(false)
const bankdraftPromptTitle = ref(texts.promptTitles.editBankdraft)
const partialbankdraftPromptTitle = ref(texts.promptTitles.editPartialbankdraft)
const invoicesMaxNumberFormFieldsLogicMixin = new InvoicesMaxNumberFormFieldsLogicMixin()
const billingMixin = new BillingMixin()

// COMPOSABLES
const { prepareFormData, registerFormData, isValidFormData } = useFormData()
const { getDialogInfo, getDialogWarning } = useDialog()
const { breakpointName } = useVuetify()
const {
  customerInvoiceId,
  invoiceCode,
  totals,
  bankdraftList,
  existsEndpointError,
  customerInvoice,
  removeCustomerInvoiceBankdraft,
  removePartialbankdraft,
  saveNewPartialbankdraft,
  saveExistPartialbankdraft,
  saveCustomerInvoice,
  automaticInsert,
  loadCustomerInvoice,
  loadTotals,
  loadBankdraftList,
  getBankdraft
} = useCustomerInvoiceBankdraft()

const { generatorFormContext, generatorFormSchema, loadGeneratorFormSchema } = useBankdraftGeneratorForm()
const { bankdraftFormContext, bankdraftFormSchema, saveBankdraftForm, loadBankdraftFormSchema } = useBankdraftForm()
const { partialbankdraftFormContext, partialbankdraftFormSchema, loadPartialbankdraftFormSchema } =
  usePartialbankdraftForm()
const { gridConfiguration } = useGridConfiguration({
  context: ListNames.CUSTOMER_BANKDRAFTS_DETAILS,
  listName: ListNames.CUSTOMER_BANKDRAFTS_DETAILS,
  commandButtons: [
    {
      id: GridActionName.PARTIALBANKDRAFT_ADD,
      type: 'None',
      title: texts.contextMenuItems.addPartialbankdraft,
      buttonOption: {
        iconCss: Icons.HAND_SPHERE,
        cssClass: 'custombutton'
      }
    },
    {
      id: GridActionName.REMOVE,
      type: 'None',
      title: texts.buttons.remove,
      buttonOption: {
        iconCss: Icons.REMOVE,
        cssClass: 'custombutton'
      }
    }
  ]
})

const getPartialBankdraftList = computed(() =>
  bankdraftList.value.reduce((acc: any[], item: { partialBankdraft: string | any[] }) => {
    if (item.partialBankdraft && item.partialBankdraft.length > 0) {
      acc.push(...item.partialBankdraft)
    }
    return acc
  }, [])
)

const miniGrid = ref({
  dataSource: getPartialBankdraftList,
  queryString: 'bankdraftId',
  allowSorting: true,
  rowHeight: '40',
  columns: [
    { field: 'chargeDate', headerText: 'Fecha de cobro', width: 30, allowSorting: true },
    { field: 'amount', headerText: 'Importe', width: 30, allowSorting: true },
    { field: 'notes', headerText: 'Nota', width: 200, allowSorting: true },
    {
      width: 60,
      headerText: '',
      allowResizing: false,
      allowEditing: false,
      allowSearching: false,
      allowReordering: false,
      textAlign: 'Left',
      customAttributes: {
        class: 'commands-columns'
      },
      showInColumnChooser: false,
      commands: [
        {
          id: GridActionName.REMOVE,
          type: 'None',
          title: texts.contextMenuItems.remove,
          buttonOption: {
            iconCss: 'lf-icon-trash',
            cssClass: 'custombutton'
          }
        }
      ]
    }
  ],
  contextMenuItems: [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.REMOVE,
      text: texts.contextMenuItems.remove,
      iconCss: Icons.REMOVE
    }
  ],
  contextMenuClick: (args: any) => {
    const { item, rowInfo } = args
    switch (item.id) {
      case ActionName.EDIT:
        showPartialbankdraftEditForm(rowInfo.rowData)
        break
      case ActionName.REMOVE:
        showRemovePartialbankdraftDialog(rowInfo.rowData as Partialbankdraft)
        break
    }
  },
  commandClick: (args: any) => {
    const { rowData } = args
    showRemovePartialbankdraftDialog(rowData as Partialbankdraft)
  },
  recordClick: (args: any) => {
    const { rowData } = args
    showPartialbankdraftEditForm(rowData)
  }
})

const onRowDataBound = ({ row, data }: RowDataBoundEventArgs) => {
  const { partialBankdraft } = data as any
  // remove caret when no partial bankdraft
  if (partialBankdraft.length === 0) {
    const rowElement = row
    const cellElement = rowElement!.querySelector('td')
    cellElement!.innerHTML = ' '
    cellElement!.className = 'e-customizedexpandcell'
  }
}

const { viewPermission, permissionsEntity } = usePermissions()
// COMPUTED
const breakpointClass: ComputedRef<string> = computed(() => String(breakpointName))
const toolbarOptionsCustom = computed((): ItemModel[] => {
  return [
    {
      id: GridActionName.TITLE,
      text: texts.gridTitle,
      cssClass: 'lf-title',
      align: 'Left'
    },
    {
      id: GridActionName.ADD,
      text: texts.buttons.add,
      align: 'Right',
      tooltipText: texts.buttons.add,
      cssClass: 'lf-btn-model4',
      disabled: !isValidFormData(RenderedForm.CUSTOMER_BANKDRAFTS_FORM)
    },
    {
      id: GridActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
      align: 'Right',
      cssClass: 'lf-btn-model1',
      prefixIcon: Icons.KEBAB,
      contextMenuItems: [
        {
          id: GridActionName.PRINT,
          iconCss: Icons.PRINT,
          text: texts.buttons.print
        },
        {
          id: GridActionName.EXPORT,
          iconCss: Icons.DOWNLOAD,
          text: texts.buttons.export
        }
      ]
    }
  ]
})

const contextMenuItems = computed(() => {
  const { canDelete } = viewPermission.value

  return [
    {
      id: ActionName.EDIT,
      text: texts.contextMenuItems.edit,
      iconCss: Icons.EDIT
    },
    {
      id: ActionName.ADD,
      text: texts.contextMenuItems.addPartialbankdraft,
      iconCss: Icons.HAND_SPHERE
    },
    ...(canDelete
      ? [
          {
            id: ActionName.REMOVE,
            text: texts.contextMenuItems.remove,
            iconCss: Icons.REMOVE
          }
        ]
      : [])
  ]
})

// EVENTS
function onAutomaticBankdraft() {
  if (bankdraftList.value.length > 0) {
    getDialogInfo({
      message: t('components.customer_invoices_bankdrafts.new_automatic_bankdraft', {
        invoiceName: invoiceCode
      }).toString(),
      action: generateAutomaticBankdraft,
      mainButtonText: texts.buttons.save,
      secondaryButtonText: texts.buttons.cancel
    })
  } else {
    generateAutomaticBankdraft()
  }
}

async function onChangeGeneratorForm(_schema: [], formData: object, field: any, value: any) {
  await prepareFormData(formData)

  // paymentType change
  if (field.id === InvoicesFormFields.PAYMENT_TYPE) {
    Vue.set(formData, InvoicesFormFields.EXPIRATION_DAY_BETWEEN, value ? value.daysBetweenDueDate : 0)
    Vue.set(formData, InvoicesFormFields.EXPIRATION_DAY_FIRST, value ? value.daysFirstDueDate : 0)
    Vue.set(formData, InvoicesFormFields.EXPIRATION_NUMBER, value ? value.numDueDate : 0)
    Vue.set(formData, InvoicesFormFields.EXPIRATION_TEXT, value ? value.text : '')
  }

  enableOrDisableGeneratorButton()
}

async function onChangeBankdraftForm(schema: [], formData: any, field: any, value: any) {
  // Charged date field logic when payment type provission selected - Billing mixin
  if (field.id === TaxesFormFields.CHARGED) {
    billingMixin.chargedSwitchLogic(schema, formData, field, value)
  }
}

function resetBankdraftFormSchema() {
  const chargeDateInput = bankdraftFormSchema.value.find((item: any) => {
    return item.id === InvoicesFormFields.CHARGE_DATE
  }) as any
  chargeDateInput.disabled = true
}

async function onBankdraftPromptExecuteAction(actionName: string) {
  if (ActionName.SAVE === actionName) {
    await saveBankdraftForm(bankdraftFormData.value, customerInvoiceId)
    if (!existsEndpointError.value) {
      await reloadData()
    }
  }
  resetBankdraftFormSchema()
  bankdraftPromptOpened.value = false
}

async function onPartialbankdraftPromptExecuteAction(actionName: string) {
  if (ActionName.SAVE === actionName) {
    await savePartialbankdraft()
    if (!existsEndpointError.value) {
      await reloadData()
    }
  }
  partialbankdraftPromptOpened.value = false
}

function onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
  switch (commandColumn.id) {
    case GridActionName.PARTIALBANKDRAFT_ADD:
      showPartialbankdraftCreateForm(rowData)
      break
    case GridActionName.REMOVE:
      showRemoveBankdraftDialog((rowData as any).id, (rowData as any).description)
      break
  }
}

function onToolbarClicked({ item }: ClickEventArgs) {
  if (ActionName.ADD === item.id) {
    showBankdradftCreateForm()
  }
}

function onRowClicked({ rowData }: RecordClickEventArgs) {
  showBankdradftEditForm(rowData)
}

function onContextMenuClicked({ item, rowInfo }: ContextMenuClickEventArgs) {
  switch (item.id) {
    case ActionName.EDIT:
      showBankdradftEditForm(rowInfo?.rowData)
      break
    case ActionName.ADD:
      showPartialbankdraftCreateForm(rowInfo?.rowData)
      break
    case ActionName.REMOVE:
      showRemoveBankdraftDialog((rowInfo?.rowData as any).id, (rowInfo?.rowData as any).description)
      break
  }
}

// METHODS
function showRemoveBankdraftDialog(bankdraftId: number, bankdraftDescription: string) {
  getDialogInfo({
    message: t('components.dialog.remove_bankdraft', { bankdraftDescription }).toString(),
    action: () => doRemoveBankdraft(bankdraftId, bankdraftDescription),
    mainButtonText: texts.buttons.remove,
    secondaryButtonText: texts.buttons.cancel
  })
}

function showRemovePartialbankdraftDialog(partialbankdraft: Partialbankdraft) {
  getDialogInfo({
    message: t('components.dialog.remove_partialbankdraft', {
      partialbankdraftNotes: partialbankdraft.notes
    }).toString(),
    action: () => doRemovePartialbankdraft(partialbankdraft),
    mainButtonText: texts.buttons.remove,
    secondaryButtonText: texts.buttons.cancel
  })
}

async function generateAutomaticBankdraft() {
  const restore = {
    ...registerFormData.value
  }
  const formData = {
    ...registerFormData.value,
    [InvoicesFormFields.PAYMENT_TYPE]: registerFormData.value.idPaymentType?.id
  }
  await prepareFormData(formData)
  await saveCustomerInvoice(customerInvoiceId)
  await automaticInsert(customerInvoiceId)
  await prepareFormData(restore)
  if (!existsEndpointError.value) {
    await reloadData()
  }
}

function cloningSelectedRegisterCompanyInfo() {
  const clonedRegisterData = JSON.parse(JSON.stringify(customerInvoice))
  const { idCompany, exercise, sequence, number: invoiceNumber } = clonedRegisterData
  selectedInvoiceCompanyInfoCloned.value = {
    idCompany: String(idCompany),
    exercise,
    sequence,
    number: invoiceNumber
  }
}

function showBankdradftCreateForm() {
  bankdraftPromptTitle.value = texts.promptTitles.newBankdraft
  bankdraftFormData.value = {}
  bankdraftPromptOpened.value = true
}

async function showBankdradftEditForm(item: any) {
  bankdraftPromptTitle.value = texts.promptTitles.editBankdraft
  bankdraftFormData.value = await getBankdraft(item.id)
  bankdraftPromptOpened.value = true
}

function showPartialbankdraftCreateForm(bankdraft: any) {
  if (texts.charged === bankdraft.chargedText) {
    getDialogWarning({
      message: t('components.dialog.partialbankdraft_create_advise').toString()
    })
    return
  }
  bankdraftId.value = bankdraft.id
  partialbankdraftPromptTitle.value = texts.promptTitles.newPartialbankdraft
  partialbankdraftFormData.value = {}
  partialbankdraftPromptOpened.value = true
}

function showPartialbankdraftEditForm(item: any) {
  partialbankdraftPromptTitle.value = texts.promptTitles.editPartialbankdraft
  partialbankdraftFormData.value = item
  partialbankdraftPromptOpened.value = true
}

async function savePartialbankdraft() {
  partialbankdraftFormData.value.id
    ? saveExistPartialbankdraft(partialbankdraftFormData.value)
    : saveNewPartialbankdraft(partialbankdraftFormData.value, bankdraftId.value)
  if (!existsEndpointError.value) {
    await reloadData()
  }
}

function enableOrDisableGeneratorButton() {
  const { canSave } = viewPermission.value
  let formButton = generatorFormSchema.value.find((item: any) => {
    return item.id === InvoicesFormFields.FORM_BUTTON
  }) as any
  canSave && registerFormData.value[InvoicesFormFields.EXPIRATION_NUMBER] > 0
    ? (formButton.disabled = false)
    : (formButton.disabled = true)
}

async function doRemoveBankdraft(bankdraftId: number, bankdraftDescription: string) {
  await removeCustomerInvoiceBankdraft(bankdraftId, bankdraftDescription)
  if (!existsEndpointError.value) {
    bankdraftPromptOpened.value = false
    await reloadData()
  }
}

async function doRemovePartialbankdraft(partialbankdraft: Partialbankdraft) {
  await removePartialbankdraft(partialbankdraft)
  if (!existsEndpointError.value) {
    await reloadData()
  }
}

async function reloadData() {
  loading.value = true
  await loadCustomerInvoice(customerInvoiceId)
  await loadBankdraftList(customerInvoiceId)
  await loadTotals(customerInvoiceId)
  loading.value = false
}

// HOOKS
onMounted(async () => {
  loading.value = true
  await loadCustomerInvoice(customerInvoiceId)
  await prepareFormData(customerInvoice)
  await loadTotals(customerInvoiceId)
  await loadGeneratorFormSchema()
  await loadBankdraftList(customerInvoiceId)
  await loadBankdraftFormSchema()
  await loadPartialbankdraftFormSchema()
  cloningSelectedRegisterCompanyInfo()

  enableOrDisableGeneratorButton()
  loading.value = false
})
</script>

<style scoped lang="scss">
.customer-invoices-bankdrafts-container {
  &.xl {
    ::v-deep .hidden-field {
      display: none !important;
    }
  }

  .spinner-layer {
    --spinner-layer-min-height: 470px;
  }

  .tab-header {
    text-transform: uppercase;
  }

  .row {
    justify-content: center;
    margin: 0;
  }

  .customer-invoices-form,
  .billing-totals,
  .grid-container {
    padding: 0;
  }

  .billing-totals {
    padding-top: 20px;
  }

  .grid-container {
    ::v-deep .e-detailheadercell + .e-headercell .e-headertext {
      display: none;
    }
  }
}
</style>
