import { URLS } from '@/router/routes/urlRoutes'
import { getCurrentInstance } from 'vue'
import { Dictionary } from 'vue-router/types/router'

export default function useRouter() {
  const instance = getCurrentInstance()
  const router = instance?.proxy.$router // Acceder a la instancia del router de Vue 2.7
  const route = instance?.proxy.$route

  function goBack() {
    router?.go(-1)
  }

  function goToRoute({name, params = {}}: {name: string, params?: Dictionary<string>}) {
    if (router?.currentRoute.name === name) {
      return
    }

    router?.push({
      name,
      params
    })
  }

  function resolveRoute({name, params = {}}: {name: string, params?: Dictionary<string>}) {
    return router?.resolve({
      name,
      params
    })
  }

  function resolveQueryRoute({name, query = {}}: {name: string, query?: Dictionary<string>}) {
    return router?.resolve({
      name,
      query
    })
  }

  function openRouteInNewWindow({name, params = {}}: {name: string, params?: Dictionary<string>}): void {
    window.open(resolveRoute({name, params})?.href, '_blank')
  }

  function goToDashboard() {
    router?.push({
      name: URLS.DASHBOARD
    })
  }


  return {
    goBack,
    goToRoute,
    router,
    route,
    resolveRoute,
    resolveQueryRoute,
    openRouteInNewWindow,
    goToDashboard
  }
}
