import { GetterTree } from 'vuex'
import { RootState } from '@/store/types/storeGlobalTypes'
import {
  AlertMessage, AlertMessageList,
  AlertMessageOrderEnum,
  AlertsMessagesState,
  ListStatus
} from '@/store/modules/alertsMessages/alertsMessagesTypes'

export const getters: GetterTree<AlertsMessagesState, RootState> = {
  getAlertsMessagesListData(state: AlertsMessagesState): AlertMessage[] {
    return state.alertsMessagesList.data
  },
  getAlertsMessagesListStatus(state: AlertsMessagesState): ListStatus {
    return state.alertsMessagesList.status
  },
  getFilteredList(state: AlertsMessagesState): AlertMessageList {
    return state.filteredList
  },
  getFilteredListStatus(state: AlertsMessagesState): ListStatus {
    return state.filteredList.status
  },
  getSelectedOrder(state: AlertsMessagesState): AlertMessageOrderEnum {
    return state.filter.order
  },
  userHasAlerts(state: AlertsMessagesState): boolean {
    return state.hasAlert
  }
}
