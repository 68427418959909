<template lang="pug">

div(:class="['customer-invoices-general-data-container', breakpointClass, 'pa-0']")

  SpinnerLayerComponent(v-if="loadingData" class="spinner-layer")

  template(v-else)
    v-row
      v-col(
        cols="12"
        :lg="initialRenderMode && !isProviderBankdraftTab && !formFullWidth ? 8 : 12"
        :xl="initialRenderMode && !isProviderBankdraftTab && !formFullWidth ? 9 : 12"
        class="customer-invoices-form"
      )
        TabHeaderComponent(
          v-if="!initialRenderMode"
          @closeForm="closeDetailForm"
          @saveForm="onSaveForm"
          @removeItem="onTabHeaderComponentRemove"
          :buttons="buttons"
          :title="formTitle"
          class="tab-header"
        )

        FormGeneratorComponent(
          v-if="formSchema && fieldValues"
          :schema="formSchema"
          :fieldValues="fieldValues"
          :context="invoiceInfo.context"
          validateOnLoad
          @lexonButtonEvent="lexonButtonEvent"
          @saveFormData="onChangeFormFieldValue"
          @emit-on-blur-event="fillTextfieldFields(fieldValues, selectedInvoiceCompanyInfoCloned)"
          :permissionsEntity="permissionsEntity"
        )

      v-col(
        v-if="initialRenderMode && showTotalsWidgetAndGrid"
        cols="12"
        :lg="isProviderBankdraftTab ? 12 : 4"
        :xl="isProviderBankdraftTab ? 12: 3"
        class="isProviderBankdraftTab ? provider-invoice-totals : billing-totals"
      )
        LfTotalsComponent(
          v-if="isProviderBankdraftTab"
          :items="totals"
        )
        BillingTotalsContainerComponent(
          v-else
          :items="totals"
        )
    v-row(v-if="initialRenderMode && showTotalsWidgetAndGrid")
      v-col(
        cols="12"
        class="grid-container"
      )
        LfGridComponent(
          :gridConfiguration="gridConfiguration"
          :itemData="listItems"
          :toolbarOptions="toolbarOptionsCustom"
          :contextMenuItems="contextMenuItems"
          :commandClick="onCommandClick"
          @toolbarClicked="onToolbarClicked"
          @rowClick="onRowClicked"
          @contextMenuClicked="onContextMenuClicked"
          :exportName="texts.gridTitle"
        )
        PromptDialogComponent(
          :title="formTitle"
          :isOpened="openedPrompt"
          :context="invoiceInfo.detailForm"
          :formSchema="formDetailSchema"
          :formFieldValues="formData"
          :width="950"
          @execute-action="onExecuteAction"
          @changeFormField="onChangeFormFieldValue"
        )

</template>

<script lang="ts">
import LfTotalsComponent from '@/components/LfTotals/LfTotalsComponent.vue'
import BillingMixin from '@/mixins/BillingMixin.vue'
import BillingTotalsContainerComponent from '@/components/billing/BillingTotals/BillingTotalsContainerComponent.vue'
import FormGeneratorComponent from '@/components/forms/FormGenerator/FormGeneratorComponent.vue'
import InvoicesMaxNumberFormFieldsLogicMixin from '@/mixins/InvoicesMaxNumberFormFieldsLogic/InvoicesMaxNumberFormFieldsLogicMixin.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import SimpleGridTableComponent from '@/components/grids/SimpleGridTable/SimpleGridTableComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'
import TabHeaderComponent from '@/components/tabs/TabHeader/TabHeaderComponent.vue'
import TaxesDynamicFormFieldsMixin from '@/mixins/TaxesDynamicFormFields/TaxesDynamicFormFieldsMixin.vue'
import { Action, Getter, Mutation } from 'vuex-class'
import { ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { ConfigurationTypes, ListNames } from '@/store/modules/configuration/configurationTypes'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { Endpoint, endpoints } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { Icons } from '@/icons/icons'
import {
  InvoiceMaxNumberFormFields,
  InvoiceMaxNumberFormFieldsType
} from '@/mixins/InvoicesMaxNumberFormFieldsLogic/types/InvoicesMaxNumberFormFieldsLogicMixinTypes'
import { InvoiceTypes } from '@/store/modules/invoices/invoicesTypes'
import {
  InvoicesFormFields,
  InvoiceTabName,
  RenderedForm
} from '@/components/billing/InvoicesTab/types/InvoicesTabComponentTypes'
import { TaxesFormFields } from '@/mixins/TaxesDynamicFormFields/types/TaxesDynamicFormFieldsMixinTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { ExpedientCustomerFact } from '@/store/modules/expedients/expedientsTypes'
import { TranslateResult } from 'vue-i18n'
import { ContextMenuClickEventArgs, DetailRow, RecordClickEventArgs } from '@syncfusion/ej2-vue-grids'
import LfGridComponent from '@/components/grids/LfGrid/LfGridComponent.vue'
import PromptDialogComponent from '@/components/Dialog/PromptDialogComponent.vue'
import { ActionName as GridActionName, ClickEventArgs, CommandModel } from '@/components/grids/LfGrid/LfGridTypes'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'

const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule = ModuleNamespaces.DIALOG
const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES
const errorsModule = ModuleNamespaces.ERROR
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const formsModule = ModuleNamespaces.FORMS
const invoicesModule = ModuleNamespaces.INVOICES
const listItemsModule = ModuleNamespaces.LIST_ITEMS
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    LfTotalsComponent,
    BillingTotalsContainerComponent,
    FormGeneratorComponent,
    LfGridComponent,
    PromptDialogComponent,
    SimpleGridTableComponent,
    SpinnerLayerComponent,
    TabHeaderComponent
  },
  provide: {
    grid: [DetailRow]
  }
})
export default class InvoicesTabComponent extends Mixins(
  TaxesDynamicFormFieldsMixin,
  InvoicesMaxNumberFormFieldsLogicMixin,
  BillingMixin,
  PermissionsMixin
) {
  @Prop({
    type: String
  })
  name!: string

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  @Getter('getCurrentViewConfiguration', { namespace: configurationModule })
  getCurrentViewConfiguration: (context: string) => []

  @Getter('getCurrentListConfiguration', { namespace: configurationModule })
  listConfiguration: object

  @Getter('getListItemsWithFormattedDates', { namespace: listItemsModule })
  listItems: []

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('existsEndpointErrorStatus', { namespace: errorsModule })
  existsEndpointError: boolean

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => {}

  @Getter('getRouteFromName', { namespace: configurationModule })
  routeFromName: string | undefined

  @Getter('getExpedientCustomerFact', { namespace: expedientsModule })
  expedientCustomerFactGetter: ExpedientCustomerFact | null

  @Action('clearEndpointError', { namespace: errorsModule }) clearEndpointError: () => {}

  @Action('saveRegisterFormData', { namespace: formsModule })
  saveRegisterFormData: ({}) => Promise<number>

  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfigurationAction: ({}) => Promise<{}>

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string, context: string) => Promise<void>

  @Action('fetchInvoiceTotals', { namespace: invoicesModule })
  fetchInvoiceTotals: ({}) => Promise<[]>

  @Action('fetchInvoiceDetails', { namespace: invoicesModule })
  fetchInvoiceDetails: ({}) => Promise<[]>

  @Action('removeInvoiceDetail', { namespace: invoicesModule })
  removeInvoiceDetailAction: ({}) => Promise<void>

  @Action('saveInvoiceDetail', { namespace: invoicesModule })
  saveInvoiceDetail: ({}) => Promise<void>

  @Action('createPartialbankdraftAction', { namespace: invoicesModule })
  createPartialbankdraftAction: ({}) => Promise<void>

  @Action('editPartialbankdraftAction', { namespace: invoicesModule })
  editPartialbankdraftAction: ({}) => Promise<void>

  @Action('removePartialbankdraftAction', { namespace: invoicesModule })
  removePartialbankdraftAction: ({}) => Promise<void>

  @Action('saveInvoiceType', { namespace: invoicesModule })
  saveInvoiceType: ({}) => Promise<void>

  @Action('showDialog', { namespace: dialogModule })
  showDialogAction: ({}) => {}

  @Action('fetchInvoiceBankdraftAutomaticInsert', { namespace: invoicesModule })
  fetchInvoiceBankdraftAutomaticInsert: ({}) => Promise<[]>

  @Mutation('REMOVE_LIST_ITEMS', { namespace: listItemsModule })
  removeListItems: () => []

  @Mutation('CHANGE_ADITIONAL_FORM_STATUS', { namespace: formsModule })
  changeAditionalFormStatus: ({}) => void

  totals = []

  loadingData = true

  initialRenderMode = true

  detailFormInEditionMode = false

  formFullWidth = false

  selectedInvoiceDetail = {}

  texts = {
    buttons: {
      close: this.$t('action_buttons.close'),
      remove: this.$t('action_buttons.remove'),
      save: this.$t('action_buttons.save')
    },
    dialogRemoveDetail: this.invoiceInfo.translateNode
      ? this.$t('components.' + this.invoiceInfo.translateNode + '.dialog_remove_detail')
      : null,
    contextMenuItems: {
      edit: this.$t('components.context_menu.edit'),
      remove: this.$t('components.context_menu.remove')
    },
    formTitles: {
      editDetail: this.invoiceInfo.translateNode
        ? this.$t('components.' + this.invoiceInfo.translateNode + '.edit_detail')
        : null,
      newDetail: this.invoiceInfo.translateNode
        ? this.$t('components.' + this.invoiceInfo.translateNode + '.new_detail')
        : null
    },
    gridTitle: this.invoiceInfo.translateNode
      ? this.$t('components.' + this.invoiceInfo.translateNode + '.grid_title')
      : null,
    noResultsSecondParagraph: this.$t('components.no_results.no_results_second_paragraph')
  }

  formData: any = {}

  selectedInvoiceCompanyInfoCloned: InvoiceMaxNumberFormFieldsType | null = null

  icons = {
    gridHeader: this.invoiceInfo.gridHeader,
    gridTable: Icons.BILLING
  }

  openedPrompt = false

  @Watch('disableGridAddNewButton')
  async checkIfFormUpdateButtonIsDisabled(isDisabled: boolean) {
    if (this.formSchema) {
      let formButton = ''
      this.formSchema.forEach((item: any) => {
        if (item.fieldset) {
          formButton = item.fields.find((itemField: any) => {
            return itemField.id === InvoicesFormFields.FORM_BUTTON
          })
        } else if (item.id === InvoicesFormFields.FORM_BUTTON) {
          formButton = item
        }
      })

      if (formButton) {
        if (!isDisabled) {
          this.$emit('enabledTabs')
        } else {
          this.$emit('disabledTabs')
        }
      }
    }
  }

  @Watch('selectedInvoiceId')
  createNewInvoice() {
    this.cloningSelectedRegisterCompanyInfo()
  }

  get modalTitle() {
    return 'Nuevo'
  }

  get gridConfiguration() {
    const columns = JSON.parse((this as any).listConfiguration['Config'])
    const { canDelete } = this.viewPermission
    const commandBtns: CommandModel[] = canDelete
      ? [
          {
            id: GridActionName.REMOVE,
            type: 'None',
            title: this.$t('action_buttons.remove').toString(),
            buttonOption: {
              iconCss: Icons.REMOVE,
              cssClass: 'custombutton'
            }
          }
        ]
      : []
    const config = {
      columns
    }
    return gridConfigurationWithActionColumn(config, commandBtns)
  }

  get toolbarOptionsCustom() {
    return [
      {
        id: GridActionName.TITLE,
        text: this.texts.gridTitle,
        cssClass: 'lf-title',
        align: 'Left'
      },
      {
        id: GridActionName.ADD,
        text: 'Añadir',
        align: 'Right',
        tooltipText: 'Añadir',
        cssClass: 'lf-btn-model4',
        disabled: !this.checkIfFormIsValid(this.invoiceInfo.renderedForm)
      },
      {
        id: GridActionName.CUSTOM_TOOLBAR_CONTEXT_MENU,
        align: 'Right',
        cssClass: 'lf-btn-model1',
        prefixIcon: Icons.KEBAB,
        contextMenuItems: [
          {
            id: GridActionName.PRINT,
            iconCss: Icons.PRINT,
            text: 'Imprimir'
          },
          {
            id: GridActionName.EXPORT,
            iconCss: Icons.DOWNLOAD,
            text: 'Exportar'
          }
        ]
      }
    ]
  }

  get isProviderBankdraftTab(): boolean {
    return InvoiceTabName.PROVIDER_BANKDRAFTS === this.name
  }

  get fieldValues() {
    return this.initialRenderMode ? this.selectedRegisterData(this.invoiceInfo.invoiceContext) : this.formData
  }

  get buttons() {
    const { canSave, canDelete } = this.checkEntityPermissionsGetter(this.permissionsEntity)
    const buttons = [
      {
        icon: Icons.REMOVE,
        tooltip: this.texts.buttons.remove,
        action: ActionName.REMOVE,
        enabled: true,
        hidden: !canDelete
      },
      {
        icon: Icons.CLOSE,
        tooltip: this.texts.buttons.close,
        class: 'red-color',
        action: ActionName.CLOSE,
        enabled: true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.texts.buttons.save,
        class: 'green-color',
        action: ActionName.SAVE,
        enabled: this.checkIfFormIsValid(this.invoiceInfo.detailForm),
        hidden: !canSave
      }
    ]

    return this.detailFormInEditionMode ? buttons : buttons.filter((button) => button.icon !== Icons.REMOVE)
  }

  get selectedInvoiceId() {
    return this.selectedRegisterId(this.invoiceInfo.invoiceContext)
  }

  get invoiceInfo() {
    const invoiceInfo = {
      type: '',
      invoiceContext: '',
      renderedForm: '',
      listName: '',
      context: '',
      detailForm: '',
      detailEndpoint: {
        get: '',
        save: '',
        delete: '',
        list: ''
      },
      totalsEndpoint: {
        get: ''
      },
      translateNode: '',
      gridHeader: Icons.CALCULATOR
    }

    switch (this.name) {
      case InvoiceTabName.CUSTOMER_GENERAL_DATA:
        invoiceInfo.type = InvoiceTypes.CUSTOMER
        invoiceInfo.invoiceContext = ContextName.BILLING_CUSTOMER_INVOICES
        ;(invoiceInfo.renderedForm = RenderedForm.CUSTOMER_GENERAL_DATA_FORM),
          (invoiceInfo.listName = ListNames.CUSTOMER_INVOICES_DETAILS),
          (invoiceInfo.context = this.initialRenderMode
            ? ContextName.CUSTOMER_INVOICES_GENERAL_DATA_FORM
            : ContextName.CUSTOMER_INVOICES_DETAIL_FORM),
          (invoiceInfo.detailForm = RenderedForm.CUSTOMER_DETAIL_FORM),
          (invoiceInfo.detailEndpoint = endpoints.invoiceDetails),
          (invoiceInfo.totalsEndpoint = endpoints.invoiceTotals),
          (invoiceInfo.translateNode = 'customer_invoices_general_data')
        break
      case InvoiceTabName.PROFORMA_GENERAL_DATA:
        invoiceInfo.type = InvoiceTypes.PROFORMA
        invoiceInfo.invoiceContext = ContextName.BILLING_PROFORMA_INVOICES
        ;(invoiceInfo.renderedForm = RenderedForm.PROFORMA_GENERAL_DATA_FORM),
          (invoiceInfo.listName = ListNames.PROFORMA_INVOICES_DETAILS),
          (invoiceInfo.context = this.initialRenderMode
            ? ContextName.PROFORMA_INVOICES_GENERAL_DATA_FORM
            : ContextName.PROFORMA_INVOICES_DETAIL_FORM),
          (invoiceInfo.detailForm = RenderedForm.PROFORMA_DETAIL_FORM),
          (invoiceInfo.detailEndpoint = endpoints.invoiceDetails),
          (invoiceInfo.totalsEndpoint = endpoints.invoiceTotals),
          (invoiceInfo.translateNode = 'proforma_invoices_general_data')
        break
      case InvoiceTabName.PROVIDER_GENERAL_DATA:
        invoiceInfo.type = InvoiceTypes.PROVIDER
        invoiceInfo.invoiceContext = ContextName.BILLING_PROVIDER_INVOICES
        ;(invoiceInfo.renderedForm = RenderedForm.PROVIDER_GENERAL_DATA_FORM),
          (invoiceInfo.listName = ListNames.PROVIDER_INVOICES_DETAILS),
          (invoiceInfo.context = this.initialRenderMode
            ? ContextName.PROVIDER_INVOICES_GENERAL_DATA_FORM
            : ContextName.PROVIDER_INVOICES_DETAIL_FORM),
          (invoiceInfo.detailForm = RenderedForm.PROVIDER_DETAIL_FORM),
          (invoiceInfo.detailEndpoint = endpoints.invoiceDetails),
          (invoiceInfo.totalsEndpoint = endpoints.invoiceTotals),
          (invoiceInfo.translateNode = 'provider_invoices_general_data')
        break
      case InvoiceTabName.CUSTOMER_BANKDRAFTS:
        invoiceInfo.type = InvoiceTypes.CUSTOMER
        invoiceInfo.invoiceContext = ContextName.BILLING_CUSTOMER_INVOICES
        ;(invoiceInfo.renderedForm = RenderedForm.CUSTOMER_BANKDRAFTS_FORM),
          (invoiceInfo.listName = ListNames.CUSTOMER_BANKDRAFTS_DETAILS),
          (invoiceInfo.context = this.initialRenderMode
            ? ContextName.CUSTOMER_INVOICES_BANKDRAFTS_FORM
            : ContextName.CUSTOMER_INVOICES_BANKDRAFTS_DETAIL_FORM),
          (invoiceInfo.detailForm = RenderedForm.CUSTOMER_BANKDRAFTS_DETAIL_FORM),
          (invoiceInfo.detailEndpoint = endpoints.invoiceBankdrafts),
          (invoiceInfo.totalsEndpoint = endpoints.invoiceBankdraftTotals),
          (invoiceInfo.translateNode = 'customer_invoices_bankdrafts'),
          (invoiceInfo.gridHeader = Icons.CALENDAR_CHECK)
        break
      case InvoiceTabName.PROFORMA_PAYMENT_METHODS:
        this.formFullWidth = true
        invoiceInfo.type = InvoiceTypes.PROFORMA
        invoiceInfo.invoiceContext = ContextName.BILLING_PROFORMA_INVOICES
        invoiceInfo.renderedForm = RenderedForm.PROFORMA_PAYMENT_METHODS_FORM
        invoiceInfo.context = ContextName.PROFORMA_INVOICES_PAYMENT_METHODS_FORM
        break
      case InvoiceTabName.PROVIDER_BANKDRAFTS:
        invoiceInfo.type = InvoiceTypes.PROVIDER
        invoiceInfo.invoiceContext = ContextName.BILLING_PROVIDER_INVOICES
        ;(invoiceInfo.renderedForm = RenderedForm.PROVIDER_BANKDRAFTS_FORM),
          (invoiceInfo.listName = ListNames.PROVIDER_BANKDRAFTS_DETAILS),
          (invoiceInfo.context = this.initialRenderMode
            ? ContextName.PROVIDER_INVOICES_BANKDRAFTS_FORM
            : ContextName.PROVIDER_INVOICES_BANKDRAFTS_DETAIL_FORM),
          (invoiceInfo.detailForm = RenderedForm.PROVIDER_BANKDRAFTS_DETAIL_FORM),
          (invoiceInfo.detailEndpoint = endpoints.invoiceBankdrafts),
          (invoiceInfo.totalsEndpoint = endpoints.invoiceBankdraftTotals),
          (invoiceInfo.translateNode = 'provider_invoices_bankdrafts'),
          (invoiceInfo.gridHeader = Icons.CALENDAR_CHECK)
        break
    }
    return invoiceInfo
  }

  get formSchema() {
    return this.getCurrentViewConfiguration(this.invoiceInfo.context)
  }

  get formDetailSchema() {
    return this.getCurrentViewConfiguration(this.invoiceInfo.detailForm)
  }

  get breakpointClass() {
    return String(this.$vuetify.breakpoint.name)
  }

  get formTitle(): string | undefined {
    return this.detailFormInEditionMode
      ? this.texts.formTitles.editDetail?.toString()
      : this.texts.formTitles.newDetail?.toString()
  }

  get selectedExpedientGeneralData(): string {
    return this.selectedRegisterData(this.invoiceInfo.invoiceContext) as any
  }

  get disableGridAddNewButton() {
    return !this.checkIfFormIsValid(this.invoiceInfo.renderedForm)
  }

  get showTotalsWidgetAndGrid() {
    return this.invoiceInfo.listName && this.invoiceInfo.totalsEndpoint
  }

  get permissionsEntity() {
    return this.$route.meta!.entityType
  }

  get contextMenuItems() {
    const { canDelete } = this.viewPermission

    return [
      {
        text: this.texts.contextMenuItems.edit,
        id: ActionName.EDIT,
        iconCss: Icons.EDIT
      },
      ...(canDelete
        ? [
            {
              text: this.texts.contextMenuItems.remove,
              id: ActionName.REMOVE,
              iconCss: Icons.REMOVE
            }
          ]
        : [])
    ]
  }

  async created() {
    if (this.invoiceInfo.listName) {
      await this.fetchCurrentListConfiguration(this.invoiceInfo.listName, this.invoiceInfo.invoiceContext)
    }
    await this.fetchCurrentViewConfigurationAction({
      objectType: ConfigurationTypes.VIEW,
      alias: this.invoiceInfo.detailForm,
      context: this.invoiceInfo.detailForm
    })

    await this.loadInitiaData()
    await this.checkIfComesFromExpedient()
    this.checkDiscountValueFieldType()
    this.checkIfGenerateBankdraftButtonIsDisabled()
    await this.checkIfFormUpdateButtonIsDisabled(this.disableGridAddNewButton)
    this.loadingData = false
  }

  mounted() {
    if (this.invoiceInfo.type) {
      this.saveInvoiceType(this.invoiceInfo.type)
    }
  }

  beforeDestroy() {
    this.removeListItems()
    this.clearEndpointError()
  }

  async loadInitiaData() {
    if (this.selectedInvoiceId) {
      await this.fetchSelectedRegisterData({
        endpoint: this.endpoints(this.entity(this.invoiceInfo.invoiceContext).type).get,
        context: this.invoiceInfo.invoiceContext
      })
    }
    await this.fetchCurrentViewConfiguration(this.invoiceInfo.renderedForm)

    if (this.showTotalsWidgetAndGrid) {
      this.totals = await this.fetchInvoiceTotals({
        endpoint: String.format(
          this.invoiceInfo.totalsEndpoint.get,
          this.invoiceInfo.type,
          String(this.selectedInvoiceId)
        )
      })

      await this.fetchInvoiceDetails({
        endpoint: String.format(
          this.invoiceInfo.detailEndpoint.list,
          this.invoiceInfo.type,
          String(this.selectedInvoiceId)
        ),
        filter: { source: this.invoiceInfo.listName }
      })
    }

    if (this.selectedInvoiceId) {
      this.cloningSelectedRegisterCompanyInfo()
    }
  }

  cloningSelectedRegisterCompanyInfo() {
    const clonedRegisterData = JSON.parse(JSON.stringify(this.selectedRegisterData(this.invoiceInfo.invoiceContext)))
    const { idCompany, exercise, sequence, number: invoiceNumber } = clonedRegisterData
    this.selectedInvoiceCompanyInfoCloned = {
      idCompany: String(idCompany),
      exercise,
      sequence,
      number: invoiceNumber
    }
  }

  async fetchCurrentViewConfiguration(formAlias: string) {
    await this.fetchCurrentViewConfigurationAction({
      objectType: ConfigurationTypes.VIEW,
      alias: formAlias,
      context: this.invoiceInfo.context
    })
  }

  resetFormData() {
    this.formData = {}
    this.prepareFormData(this.formData)
  }

  lexonButtonEvent() {
    this.updateTotals()
  }

  async updateTotals() {
    this.loadingData = true

    this.regularizeFieldsOnSave()
    await this.saveRegisterFormData({
      endpoint: this.endpoints(this.entity(this.invoiceInfo.invoiceContext).type).save,
      idSelectedRegister: this.selectedInvoiceId,
      context: this.invoiceInfo.invoiceContext
    })

    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.invoiceInfo.invoiceContext).type).get,
      context: this.invoiceInfo.invoiceContext
    })

    if (!this.existsEndpointError) {
      this.totals = await this.fetchInvoiceTotals({
        endpoint: String.format(
          this.invoiceInfo.totalsEndpoint.get,
          this.invoiceInfo.type,
          String(this.selectedInvoiceId)
        )
      })
    }

    await this.loadInitiaData()
    this.checkDiscountValueFieldType()
    this.checkIfFormUpdateButtonIsDisabled(this.disableGridAddNewButton)
    this.loadingData = false
  }

  async openCustomerInvoicesDetailForm() {
    this.detailFormInEditionMode = false
    this.regularizeFieldsOnSave()
    await this.saveRegisterFormData({
      endpoint: this.endpoints(this.entity(this.invoiceInfo.invoiceContext).type).save,
      idSelectedRegister: this.selectedInvoiceId,
      context: this.invoiceInfo.invoiceContext
    })
    this.resetFormData()
    if (!this.existsEndpointError) {
      this.openedPrompt = true
    }
  }

  async closeDetailForm() {
    this.loadingData = true
    await this.loadInitiaData()
    this.loadingData = false
    this.initialRenderMode = true
    this.changeAditionalFormStatus({
      status: false,
      context: this.invoiceInfo.invoiceContext
    })
    this.detailFormInEditionMode = false
    this.resetFormData()
  }

  async checkSaveDetail() {
    let invoice: any = null
    let dialogAdvice = '' as TranslateResult
    let invoiceIsChargedOrPaid = null
    let formDataIsNotChargedOrPaid = null

    if (this.invoiceInfo.type === InvoiceTypes.PROVIDER) {
      invoice = this.selectedRegisterData(ContextName.BILLING_PROVIDER_INVOICES)
      dialogAdvice = this.$t('components.dialog.invoice_provider_bankdraft_advise')
      invoiceIsChargedOrPaid = Number(invoice.paid) === 1
      formDataIsNotChargedOrPaid = Number(this.formData.paid) === 0
    } else if (this.invoiceInfo.type === InvoiceTypes.CUSTOMER) {
      invoice = this.selectedRegisterData(ContextName.BILLING_CUSTOMER_INVOICES)
      dialogAdvice = this.$t('components.dialog.invoice_customer_bankdraft_advise')
      invoiceIsChargedOrPaid = Number(invoice.charged) === 1
      formDataIsNotChargedOrPaid = Number(this.formData.charged) === 0
    }

    if (invoice && invoiceIsChargedOrPaid && formDataIsNotChargedOrPaid) {
      this.showDialogAction({
        type: DialogTypes.WARNING,
        message: dialogAdvice,
        action: this.saveDetail
      })
    } else {
      this.saveDetail()
    }
  }

  async saveDetail() {
    await this.saveInvoiceDetail({
      endpoint: String.format(this.invoiceInfo.detailEndpoint.save, this.invoiceInfo.type),
      formData: { ...this.formData, idInvoice: String(this.selectedInvoiceId) }
    })

    if (!this.existsEndpointError) {
      this.closeDetailForm()
    }
  }

  async onSaveForm() {
    this.checkSaveDetail()
  }

  onTabHeaderComponentRemove() {
    this.showRemoveInvoiceDetailDialog()
  }

  showRemoveInvoiceDetailDialog() {
    this.showDialogAction({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        register: (this as any).selectedInvoiceDetail.description,
        text: this.texts.dialogRemoveDetail
      }),
      action: this.removeInvoiceDetail,
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel')
    })
  }

  async removeInvoiceDetail() {
    await this.removeInvoiceDetailAction({
      endpoint: String.format(
        this.invoiceInfo.detailEndpoint.delete,
        this.invoiceInfo.type,
        (this as any).selectedInvoiceDetail.id
      ),
      invoiceName: (this as any).selectedInvoiceDetail.description
    })

    if (this.initialRenderMode) {
      await this.fetchInvoiceDetails({
        endpoint: String.format(
          this.invoiceInfo.detailEndpoint.list,
          this.invoiceInfo.type,
          String(this.selectedInvoiceId)
        ),
        filter: { source: this.invoiceInfo.listName }
      })
      this.totals = await this.fetchInvoiceTotals({
        endpoint: String.format(
          this.invoiceInfo.totalsEndpoint.get,
          this.invoiceInfo.type,
          String(this.selectedInvoiceId)
        )
      })
    } else if (!this.existsEndpointError) {
      this.closeDetailForm()
    }
  }

  async editInvoiceDetail(selectedInvoiceDetail: object, _principal: boolean, _args: any) {
    this.lastDynamicFields = []
    this.selectedInvoiceDetail = selectedInvoiceDetail
    this.detailFormInEditionMode = true
    await this.$nextTick()
    this.formData = await this.fetchInvoiceDetails({
      endpoint: String.format(
        this.invoiceInfo.detailEndpoint.get,
        this.invoiceInfo.type,
        (this as any).selectedInvoiceDetail.id
      )
    })
    if (!this.existsEndpointError) {
      this.openedPrompt = true
    }
  }

  async onChangeFormFieldValue(schema: [], formData: any, field: any, value: any) {
    this.prepareFormData(formData)

    // Adding new fields depending on type selected
    if (field.id === TaxesFormFields.TYPE) {
      this.addTaxesFields(schema, value)
    }

    // Charged date field logic when is paid - Billing mixin
    if (field.id === InvoicesFormFields.PAID || (this.openedPrompt && !formData?.paid)) {
      ;(this as any).paidSwitchLogic(schema, formData, field, value)
    }

    // Charged date field logic when payment type provission selected - Billing mixin
    if (field.id === TaxesFormFields.CHARGED) {
      this.chargedSwitchLogic(schema, formData, field, value)
    }

    // Corrective disable logic
    if (field.id === InvoicesFormFields.CORRECTIVE_SWITCH) {
      const correctiveSelect = schema.find((item: any) => {
        return item.id === InvoicesFormFields.CORRECTIVE_SELECT
      }) as any
      if (correctiveSelect) {
        if (Number(value)) {
          correctiveSelect.disabled = false
          correctiveSelect.validationRules = 'required'
        } else {
          correctiveSelect.disabled = true
          correctiveSelect.validationRules = null
          Vue.delete(formData, InvoicesFormFields.CORRECTIVE_SELECT)
        }
      }
    }

    // Company field logic
    if (field.id === InvoiceMaxNumberFormFields.COMPANY) {
      await this.fetchMaxNumberInvoice(value, formData)

      if (this.selectedInvoiceCompanyInfoCloned && this.selectedInvoiceCompanyInfoCloned.sequence === '') {
        Vue.set(formData, InvoiceMaxNumberFormFields.SEQUENCE, '')
      }

      if (
        this.selectedInvoiceCompanyInfoCloned &&
        formData &&
        this.selectedInvoiceCompanyInfoCloned.idCompany === value &&
        String(this.selectedInvoiceCompanyInfoCloned.exercise) === String((formData as any)['exercise']) &&
        String(this.selectedInvoiceCompanyInfoCloned.sequence) === String((formData as any)['sequence'])
      ) {
        Vue.set(formData, InvoiceMaxNumberFormFields.INVOICE_NUMBER, this.selectedInvoiceCompanyInfoCloned.number)
      }
    }

    // Launch max number invoice API call in some fields
    if (
      (field.id === InvoiceMaxNumberFormFields.EXERCISE || field.id === InvoiceMaxNumberFormFields.SEQUENCE) &&
      (formData as any).idCompany
    ) {
      this.makeMaxNumberActions()
    }

    // discount type LexonNumeric percetage or number
    if (field.id === InvoicesFormFields.DISCOUNT_TYPE) {
      this.checkDiscountValueFieldType(value)
      this.enableOrDisableDiscountValueFormField(value)
    }

    // Disabled form button if no discount value
    if (field.id === InvoicesFormFields.DISCOUNT_VALUE) {
      const formButton = this.selectFormButton()
      const discountValueFormField = this.selectDiscountValueFormField()
      if (value >= 0) {
        discountValueFormField.disabled = false
        formButton.disabled = false
      } else {
        formButton.disabled = true
      }
    }

    // paymentType change
    if (field.id === InvoicesFormFields.PAYMENT_TYPE) {
      Vue.set(formData, InvoicesFormFields.EXPIRATION_DAY_BETWEEN, value ? value.daysBetweenDueDate : 0)
      Vue.set(formData, InvoicesFormFields.EXPIRATION_DAY_FIRST, value ? value.daysFirstDueDate : 0)
      Vue.set(formData, InvoicesFormFields.EXPIRATION_NUMBER, value ? value.numDueDate : 0)
      Vue.set(formData, InvoicesFormFields.EXPIRATION_TEXT, value ? value.text : '')
    }

    if (field.id === InvoicesFormFields.EXPIRATION_NUMBER && this.name !== InvoiceTabName.PROFORMA_PAYMENT_METHODS) {
      const formButton = this.selectFormButton()
      const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)
      value > 0 && canSave ? (formButton.disabled = false) : (formButton.disabled = true)
    }
  }

  selectDiscountValueFormField() {
    const discountFieldset = this.formSchema.find((item: any) => {
      return item.fieldsetId === InvoicesFormFields.DISCOUNT_FIELDSET
    }) as any

    if (discountFieldset) {
      return discountFieldset.fields.find((item: any) => {
        return item.id === InvoicesFormFields.DISCOUNT_VALUE
      }) as any
    }
  }

  selectFormButton() {
    const discountFieldset = this.formSchema.find((item: any) => {
      return item.fieldsetId === InvoicesFormFields.DISCOUNT_FIELDSET
    }) as any

    if (discountFieldset) {
      return discountFieldset.fields.find((item: any) => {
        return item.id === InvoicesFormFields.FORM_BUTTON
      }) as any
    } else {
      return this.formSchema.find((item: any) => {
        return item.id === InvoicesFormFields.FORM_BUTTON
      }) as any
    }
  }

  checkIfGenerateBankdraftButtonIsDisabled() {
    if (
      this.invoiceInfo.renderedForm === RenderedForm.CUSTOMER_BANKDRAFTS_FORM ||
      this.invoiceInfo.renderedForm === RenderedForm.PROVIDER_BANKDRAFTS_FORM
    ) {
      const { canSave } = this.checkEntityPermissionsGetter(this.permissionsEntity)
      const formButton = this.selectFormButton()
      canSave && this.fieldValues[InvoicesFormFields.EXPIRATION_NUMBER] > 0
        ? (formButton.disabled = false)
        : (formButton.disabled = true)
    }
  }

  async checkIfComesFromExpedient() {
    await this.$nextTick()
    if (this.routeFromName === `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`) {
      this.fieldValues[InvoicesFormFields.EXPEDIENT_ID] = this.selectedRegisterId(ContextName.EXPEDIENTS)
      if (this.expedientCustomerFactGetter) {
        this.fieldValues[InvoicesFormFields.EXPEDIENT_CUSTOMER_FACT] = this.expedientCustomerFactGetter
      }
    }
  }

  checkDiscountValueFieldType(status: number | null = null) {
    const discountType = status || this.fieldValues[InvoicesFormFields.DISCOUNT_TYPE]

    if (discountType) {
      const discountValueField = this.selectDiscountValueFormField()
      if (discountValueField) {
        if (discountType === 1) {
          discountValueField.fieldType = 'LexonNumericComponent'
          discountValueField.percentage = true
          discountValueField.decimals = 2
          discountValueField.format = 'N'
        } else {
          discountValueField.fieldType = 'LexonCurrencyComponent'
          discountValueField.decimals = 6
          discountValueField.format = '####'
          Vue.delete(discountValueField, 'percentage')
        }
      }
    }
  }

  enableOrDisableDiscountValueFormField(selectedType: number | null = null) {
    const discountValueField = this.selectDiscountValueFormField()
    const formButton = this.selectFormButton()

    if (selectedType) {
      formButton.disabled = true
      discountValueField.disabled = false
    } else {
      formButton.disabled = true
      discountValueField.disabled = true
      discountValueField.fieldType = 'LexonNumericComponent'
      discountValueField.percentage = false
      Vue.set(this.fieldValues, InvoicesFormFields.DISCOUNT_VALUE, null)
    }
  }

  onToolbarClicked({ item }: ClickEventArgs) {
    const { id } = item
    if (ActionName.ADD === id) {
      this.openCustomerInvoicesDetailForm()
    }
  }

  onExecuteAction(action: string) {
    if (action === ActionName.SAVE) {
      this.checkSaveDetail()
    } else if (action === ActionName.CLOSE) {
      this.closeDetailForm()
    }
    this.openedPrompt = false
  }

  onCommandClick({ commandColumn, rowData }: any) {
    this.selectedInvoiceDetail = rowData

    if (commandColumn.id === ActionName.REMOVE) {
      this.showRemoveInvoiceDetailDialog()
    }
  }

  onRowClicked({ rowData }: RecordClickEventArgs) {
    this.editInvoiceDetail(rowData as object, false, null)
  }

  onContextMenuClicked({ item, rowInfo }: ContextMenuClickEventArgs) {
    const { rowData } = rowInfo as any
    this.selectedInvoiceDetail = rowData
    switch (item.id) {
      case ActionName.EDIT:
        this.editInvoiceDetail(this.selectedInvoiceDetail, false, null)
        break
      case ActionName.REMOVE:
        this.showRemoveInvoiceDetailDialog()
        break
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-invoices-general-data-container {
  &.xl {
    ::v-deep .hidden-field {
      display: none !important;
    }
  }

  .spinner-layer {
    --spinner-layer-min-height: 470px;
  }

  .tab-header {
    text-transform: uppercase;
  }

  .row {
    justify-content: center;
    margin: 0;
  }
  .customer-invoices-form,
  .billing-totals,
  .grid-container {
    padding: 0;
  }

  .provider-invoice-totals {
    padding: 20px 0 0 0;
  }

  .grid-container {
    ::v-deep .e-detailheadercell + .e-headercell .e-headertext {
      display: none;
    }
  }
}
</style>
