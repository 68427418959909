export enum ContactFields {
  BAR_ASSOCIATION = 'barAssociation',
  BIRTHDAY = 'birthDay',
  CHARTERED = 'charteredProfessional',
  CHARTERED_FIELDS = 'charteredFields',
  CHARTERED_EXTRA_FIELDS = 'charteredExtraFields',
  FIRST_SURNAME = 'firstSurname',
  LAW_FIRM = 'lawFirm',
  LEGAL_TYPE = 'idLegalType',
  LOCATION_FIELDS = 'locationFields',
  NAME = 'name',
  NUMBER = 'number',
  NUMBER_COLLEGE = 'numberCollege',
  PERSON_TYPE = 'idPersonType',
  PROFESSIONAL_TYPE = 'idProfessionalType',
  SECOND_SURNAME = 'secondSurname',
  UNNUMBERED = 'unnumbered',
  WEB = 'web'
}

export const NaturalFields = [
  ContactFields.BIRTHDAY,
  ContactFields.FIRST_SURNAME,
  ContactFields.SECOND_SURNAME
]
export const NaturalFieldsInFieldSet = [
  ContactFields.CHARTERED,
  ContactFields.PROFESSIONAL_TYPE
]

export const LegalFields = [
  ContactFields.LEGAL_TYPE
]
