<template></template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import useToasts from '@/components/Toasts/composables/useToasts'
import { Toast } from '@/components/Toasts/types/toastsTypes'

// COMPOSABLE
const { addToast, removeToast } = useToasts()

// DATA
const toastId = ref('9999999-doc-ia')

const toast = {
  id: toastId.value,
  title: 'Análisis documental inteligente',
  message:
    'Nueva funcionalidad, analiza tus documentos con IA. Este icono indica los documentos que disponen de esta funcionalidad.',
  icon: 'ia-icon.svg',
  stored: true
}

// METHODS
function checkIsInlocalStorage() {
  const toastsString = localStorage.getItem('toasts')
  let isIdPresent = false

  if (toastsString) {
    const toastsArray = JSON.parse(toastsString)
    isIdPresent = toastsArray.some((toast: Toast) => toast.id === toastId.value)
  }
  return isIdPresent
}

onMounted(() => {
  if (!checkIsInlocalStorage()) {
    addToast(toast)
  }
})

onUnmounted(() => {
  if (!checkIsInlocalStorage()) {
    removeToast(toast)
  }
})
</script>
