import { render, staticRenderFns } from "./LfGridComponent.vue?vue&type=template&id=0be4b338"
import script from "./LfGridComponent.vue?vue&type=script&lang=ts&setup=true"
export * from "./LfGridComponent.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports