
import { URLS } from '@/router/routes/urlRoutes'
import { i18n } from '@/plugins/vue-i18n'
import { router } from '@/router/router'

export default function useContacts() {

  function openContact(selectedRegisterId = '', target = '_self') {
    // TODO utilizar router de useRouter en vez de @/router/router en vue 3.0
    const selectedRegisterParam = selectedRegisterId || i18n.t('views.selected_register.new').toString()
    const routeName = `${URLS.CONTACTS}-${URLS.CONTACT}`
    const routeData = router.resolve({
      name: routeName,
      params: { selectedRegisterId: selectedRegisterParam, from: URLS.CONTACTS }
    })
    if (routeData && target === '_blank') {
      window.open((routeData as any).href, '_blank')
    } else {
      router.push((routeData as any).resolved)
    }
  }

  return {
    openContact
  }
}
