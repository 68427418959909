<template>
  <div class="lf-custom-tooltip">{{ tooltipMessage }}</div>
</template>

<script setup lang="ts">
import useTooltip from './composables/useTooltip'

const { tooltipMessage } = useTooltip()
</script>

<style scoped lang="scss">
.lf-custom-tooltip {
  @include milano-regular-14;
  display: none;
  position: absolute;
  color: $neutral-grey-800;
  background: $main-050;
  padding: $spacing-xs;
  border-radius: $cornerRadius-sm;
  z-index: 999;
  -webkit-filter: drop-shadow(0px 3px 4px rgba(113, 113, 113, 0.4));
  filter: drop-shadow(0px 3px 4px rgba(113, 113, 113, 0.4));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')";
}

.lf-custom-tooltip::after,
.lf-custom-tooltip::before {
  content: '';
  position: absolute;
  left: 50%;
}

.lf-custom-tooltip::after {
  top: 100%;
  border: solid transparent;
  border-width: 10px;
  border-top-color: $main-050;
  transform: translateX(-50%);
}

.lf-custom-tooltip::before {
  top: calc(100% - 1px);
  border: solid transparent;
  border-width: 11px;
  border-top-color: $main-050;
  transform: translateX(-50%);
  z-index: -1;
}
</style>
