import { useHistoryStore } from '@/stores/router/useHistoryStore'
import { Route } from 'vue-router'
import { URLS } from '../routes/urlRoutes'

export const historyGuard = (to: Route, _from: Route, next: () => void): void => {
  if (![URLS.LOGIN, URLS.DOUBLE_OPTIN, URLS.NAVISION_LOGIN].includes(to.name as string)) {
    const store = useHistoryStore()
    store.addHistory(to)
  }
  next()
}
