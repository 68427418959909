<template lang="pug">

  div(class="firma-dialog-container")

    //- HEADER
    div(class="header-container")
      CustomDialogHeaderComponent(:headerTitle="texts.title")

    div(class="wrapper")
      //- GRID
      Grid(
        v-if="gridItems && gridItems.length && !loadingSpinner"
        :itemData="gridItems"
        :gridConfiguration="gridConfiguration"
        :toolbarOptions="[]"
        :commandClick="onCommandClick"
        :rowDataBound="onRowDataBound"
      )

      //- INFOBAR
      InfoBarComponent(
        class="info-bar"
        v-if="showInfoBar"
        :infoText="texts.errorText"
        :type="errorBar"
      )

    //- BUTTON
    div(class="custom-dialog-action-buttons-container")
      LfLabelButtonComponent(
        v-if="gridItems && gridItems.length && !loadingSpinner"
        class="button"
        :text="texts.accept"
        :action="() => closeCustomDialogAction()"
        variant="solid"
      )

    //- SPINNER
    SpinnerLayerComponent(v-if="loadingSpinner || gridItems.length === 0" class="spinner-layer" :loadingText="spinnerText.receiving")

</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator'
import FirmaDialogsMixin from '@/mixins/firma/FirmaDialogsMixin.vue'
import { Icons } from '@/icons/icons'
import { InfoBarTypes } from '@/components/InfoBar/types/InfoBarComponentTypes'
import { Action, Getter, Mutation } from 'vuex-class'
import { ListNames } from '@/store/modules/configuration/configurationTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import {
  FirmaAudit,
  FirmaRequestStatus,
  ParsedFirmaAudit,
  FirmaRequestTypes,
  FirmaAuditDetailEndpoint
} from '@/store/modules/firma/firmaTypes'
import {
  ActionName,
  CommandClickEventArgs,
  CommandModel,
  commandButtonType
} from '@/components/grids/LfGrid/LfGridTypes'
import { gridConfigurationWithActionColumn } from '@/helpers/grid'
import { createLink } from '@/helpers/blob'
import { RowDataBoundEventArgs } from '@syncfusion/ej2-vue-grids'

const firmaModule = ModuleNamespaces.FIRMA
const configurationModule = ModuleNamespaces.CONFIGURATION
const dialogModule = ModuleNamespaces.DIALOG
const fileManagerModule = ModuleNamespaces.FILE_MANAGER

@Component({
  components: {
    LfLabelButtonComponent: () => import('@/components/forms/buttons/label/LfLabelButtonComponent.vue'),
    CustomDialogActionButtonsComponent: () =>
      import('@/components/Dialog/CustomDialog/CustomDialogActionButtonsComponent.vue'),
    CustomDialogHeaderComponent: () => import('@/components/Dialog/CustomDialog/CustomDialogHeaderComponent.vue'),
    Grid: () => import('@/components/grids/LfGrid/LfGridComponent.vue'),
    InfoBarComponent: () => import('@/components/InfoBar/InfoBarComponent.vue'),
    SpinnerLayerComponent: () => import('@/components/Spinner/SpinnerLayerComponent.vue')
  }
})
export default class MonitoringAndAuditsFirmaDialogComponent extends Mixins(FirmaDialogsMixin) {
  @Action('fetchMonitoredAuditsList', { namespace: firmaModule })
  fetchMonitoredAuditsAction: (idDocument: number) => Promise<void>

  @Action('fetchOneAuditInfo', { namespace: firmaModule })
  fetchOneAuditInfoAction: (data: any) => Promise<void>

  @Action('closeCustomDialog', { namespace: dialogModule })
  closeCustomDialogAction: () => {}

  @Action('fetchCurrentListConfiguration', { namespace: configurationModule })
  fetchCurrentListConfiguration: (alias: string) => {}

  @Action('downloadFile', { namespace: fileManagerModule })
  downloadFile: (id: string) => Blob

  @Mutation('RESET_FIRMA_STATE', { namespace: firmaModule })
  resetFirmaState: () => void

  @Getter('getMonitoredAuditsListItems', { namespace: firmaModule })
  monitoredAuditsGetter: FirmaAudit[]

  @Getter('getAuditsStatusInfo', { namespace: firmaModule })
  auditsStatusInfoGetter: any[]

  @Getter('getCurrentListConfiguration', { namespace: configurationModule }) listConfiguration: object
  gridItems: ParsedFirmaAudit | FirmaAudit[] | [] = []

  @Getter('getFile', { namespace: fileManagerModule }) blobFile: Blob

  texts = {
    accept: this.$t('action_buttons.accept'),
    title: this.$t('components.firma_dialogs.monitoring_and_audits.title'),
    errorText: this.$t('components.firma_dialogs.monitoring_and_audits.error_text')
  }

  showInfoBar = false

  errorBar = InfoBarTypes.ERROR

  parseItemsFlag = false

  get gridConfiguration() {
    const { columns } = JSON.parse((this as any).listConfiguration['Config'])
    const config = {
      columns
    }
    return gridConfigurationWithActionColumn(config, this.commands)
  }

  get commands(): CommandModel[] {
    return [
      {
        id: ActionName.OPEN_AUDIT,
        type: commandButtonType.NONE,
        title: '',
        buttonOption: {
          iconCss: Icons.OPEN_WINDOW,
          cssClass: 'custombutton'
        }
      },
      {
        id: ActionName.DOWNLOAD_ORIGINAL_DOCUMENT,
        type: commandButtonType.NONE,
        title: '',
        buttonOption: {
          iconCss: Icons.DOWNLOAD,
          cssClass: 'custombutton download-original-document'
        }
      }
    ]
  }

  @Watch('monitoredAuditsGetter')
  async getSecondAPICallInfo() {
    await this.getAuditsInfo()
  }

  @Watch('parseItemsFlag')
  async parseGridItemsWatcher() {
    this.gridItems = this.parsedGridItens()
  }

  async created() {
    await this.fetchCurrentListConfiguration(ListNames.SIGN_AUDIT)
    await this.fetchMonitoredAuditsAction(this.signInfoGetter.selectedFileId)
  }

  beforeDestroy() {
    this.resetFirmaState()
  }

  async getAuditsInfo() {
    await Promise.all(
      this.monitoredAuditsGetter.map(async (audit: any) => {
        if (
          audit.idStatus !== FirmaRequestStatus.SENT_TO_FIRMA &&
          audit.idType !== FirmaRequestTypes.CERTIFIED_DOCUMENT
        ) {
          await this.fetchOneAuditInfoAction({ type: this.getTypeNameAudit(audit.idType), guid: audit.guid })
        }
      })
    ).finally(() => {
      this.parseItemsFlag = true
    })
  }

  getTypeNameAudit(type: FirmaRequestTypes) {
    switch (type) {
      case FirmaRequestTypes.FIRMA_REQUEST:
        return FirmaAuditDetailEndpoint.FIRMA_AUDIT_DETAIL
      case FirmaRequestTypes.CERTIFIED_EMAIL:
        return FirmaAuditDetailEndpoint.EMAIL_AUDIT_DETAIL
      case FirmaRequestTypes.CERTIFIED_SMS:
        return FirmaAuditDetailEndpoint.SMS_AUDIT_DETAIL
    }
  }

  parsedGridItens(): ParsedFirmaAudit[] {
    const itemsFirstAPICall = this.monitoredAuditsGetter
    const itemsSecondAPICall = this.auditsStatusInfoGetter
    const parsedItems: any[] = itemsFirstAPICall

    itemsFirstAPICall.forEach((a: FirmaAudit) => {
      itemsSecondAPICall.forEach((b: any) => {
        if (b && a.guid === b.guid) {
          const item = parsedItems.find((item) => item.guid === b.guid)
          item.documents = b.documents
        }
      })
    })
    return parsedItems
  }

  async onCommandClick({ commandColumn, rowData }: CommandClickEventArgs) {
    const action = commandColumn?.id
    const { idDocumentOriginal, initialFile } = rowData as any
    switch (action) {
      case ActionName.OPEN_AUDIT:
        this.seeDetails(rowData)
        break
      case ActionName.DOWNLOAD_ORIGINAL_DOCUMENT:
        if (idDocumentOriginal) {
          await this.downloadFile(idDocumentOriginal)

          const blobUrl = URL.createObjectURL(this.blobFile)
          createLink(blobUrl, initialFile)
        }
        break
      default:
        break
    }
  }

  onRowDataBound({ row, data }: RowDataBoundEventArgs) {
    const { idDocumentOriginal } = data as any
    if (!idDocumentOriginal) {
      const rowElement = row
      const downloadOriginalElement = rowElement!.querySelector('.download-original-document')
      downloadOriginalElement!.classList.add('disabled')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .e-grid .e-toolbar {
  display: none !important;
}

::v-deep .info-bar {
  margin-top: 20px;
}

.firma-dialog-container {
  width: 100% !important;
}

.custom-dialog-action-buttons-container {
  margin-top: $spacing-xl;
  @include flex($justify-content: flex-end);

  .main-action-button {
    @include label-button-solid($height: 38px);
  }
}

::v-deep td {
  vertical-align: top !important;
  line-height: 2 !important;
  padding-top: 7px !important;
}

::v-deep .audit-grid-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
}

::v-deep .download-original-document.disabled {
  cursor: default !important;

  span {
    color: $neutral-grey-500;
  }
}
</style>
