import { ActionTree } from 'vuex'
import { ContactsState, contactsMutationTypes } from '@/store/modules/contacts/contactsTypes'
import { RootState, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { MainService } from '@/services/MainService'
import { ContactsService } from '@/services/ContactsService'
import { listItemsMutationTypes } from '../listItems/listItemsTypes'
import { DialogTypes } from '../dialog/dialogTypes'

const listItemsModule: string = ModuleNamespaces.LIST_ITEMS
const contactsModule: string = ModuleNamespaces.CONTACTS
const dialogModule: string = ModuleNamespaces.DIALOG

export const actions: ActionTree<ContactsState, RootState> = {
  async fetchContacts({ commit }, contactType) {
    try {
      const url = `contacts/${contactType}`
      const { data } = await new ContactsService().getData(url)
      commit(contactsMutationTypes.FETCH_CONTACTS, data)
    } catch (error) {
      commit(`${contactsModule}/RESET_CONTACTS`, null, { root: true })
    }
  },
  async fetchContactsFilter({ commit }, { contactType, params }): Promise<void> {
    try {
      const url = `contacts/${contactType}`
      const { data } = await new MainService().postData(url, params)
      commit(contactsMutationTypes.FETCH_CONTACTS, data)
      return data
    } catch (error) {
      commit(`${contactsModule}/RESET_CONTACTS`, null, { root: true })
    }
  },
  async fetchContact({ }, { contactType = null, params = {} }) {
    try {
      const url = `contact/${contactType}/get`
      const { data } = await new MainService().postData(url, params)
      return data
    } catch (error) { }
  },
  async saveContact(_context, { contactType, formData }) {
    try {
      const url = `/contacts/${contactType}/save`
      const { data } = await new MainService().postData(url, formData)
      return data
    } catch (error) {}
  },
  async deleteContact({ dispatch }, { contactType, idContact }) {
    try {
      const url = `contacts/${contactType}/${idContact}/delete`
      const { data } = await new MainService().deleteData(url)
      return Boolean(data)
    } catch (error) {
      const errorMessage = (error as any).response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  setContactTypeSelected({ commit }, contactType) {
    commit(contactsMutationTypes.CONTACT_TYPE_SELECTED, contactType)
  },
  // contactContacts
  async fetchContactsContact({ commit }, { contactType = null, idContact = null }) {
    try {
      const url = `contact/contacts/${contactType}/${idContact}/get`
      const { data } = await new MainService().getData(url)
      commit(`${listItemsModule}/${listItemsMutationTypes.SET_LIST_ITEMS}`, data, { root: true })
    } catch (error) {
      commit(`${contactsModule}/RESET_CONTACTS`, null, { root: true })
    }
  },
  async saveContactContact({ dispatch }, formData) {
    try {
      const url = `/contact/contacts/contacts/save`
      const { data } = await new MainService().postData(url, formData)
      return data
    } catch (error) {
      const errorMessage = (error as any).response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  },
  async deleteContactContact({ dispatch }, idContact) {
    try {
      const url = `/contact/contacts/contacts/${idContact}/delete`
      const { data } = await new MainService().deleteData(url)
      return Boolean(data)
    } catch (error) {
      const errorMessage = (error as any).response.data.exceptionDesc
      dispatch(
        `${dialogModule}/showDialog`,
        {
          type: DialogTypes.ERROR,
          message: errorMessage
        },
        { root: true }
      )
    }
  }

}
