import { render, staticRenderFns } from "./InvoicesTabComponent.vue?vue&type=template&id=2a9ba757&scoped=true&lang=pug"
import script from "./InvoicesTabComponent.vue?vue&type=script&lang=ts"
export * from "./InvoicesTabComponent.vue?vue&type=script&lang=ts"
import style0 from "./InvoicesTabComponent.vue?vue&type=style&index=0&id=2a9ba757&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9ba757",
  null
  
)

export default component.exports