import { computed, Ref } from 'vue'
import { GridComponent  } from '@syncfusion/ej2-vue-grids'

interface PropsUseGridSearch {
  gridRef: Ref<InstanceType<typeof GridComponent> | null>
}

interface ResponseUseGridSearch {
  search: (args?: any) => void
  searchClear: () => void
}

export default function useGridSearch(props: PropsUseGridSearch): ResponseUseGridSearch {
  const { gridRef } = props

  const search = (args?: any) => {
    const { target, value } = args
    if (target.id.includes('clearbutton')) {
      searchClear()
    }

    if (!target.id.includes('searchbutton')) {
      return
    }

    const searchText = value || searchKey.value

    if (gridRef.value) {
      ; (gridRef.value as any).search(searchText)
    }
  }

  const searchClear = () => {
    if (gridRef.value) {
      ;(gridRef.value as any).ej2Instances.searchSettings.key = ''
      const inputText = getInputSearch()
      inputText.value = ''
    }
  }

  const searchKey = computed(() => {
    if (gridRef.value) {
      const inputText = getInputSearch()
      ;(gridRef.value as any).ej2Instances.searchSettings.key = inputText.value
      return inputText.value
    }
  })

  const getInputSearch = () => {
    return document.getElementById((gridRef.value as any).$el.id + "_searchbar") as any
  }

  return {
    search,
    searchClear
  }
}
