<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { downloadExcelByArray } from '@/helpers/downloadExcel'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { Action } from 'vuex-class'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { getArrayValuesByProp } from '@/helpers/array'
import { ActionName } from '@/components/grids/LfGrid/LfGridTypes'
import { arrayValuesToString } from '@/helpers/array'

const invoicesModule = ModuleNamespaces.INVOICES
const dialogModule = ModuleNamespaces.DIALOG

@Component
export default class InvoicesToolBarMixin extends Vue {

  @Action('showDialog', { namespace: dialogModule })
  openDialog: ({}) => void
  @Action('fetchSageInvoices', { namespace: invoicesModule })
  fetchSageInvoices: ({}) => Promise<[]>

  get invoicesExportContextMenuItems() {
    return [
      {
        id: ActionName.EXPORT,
        text: 'Excel'
      },
      {
        id: ActionName.EXPORT_SAGE,
        text: 'Sage'
      }
    ]
  }

  downloadExcelGrid() {
    if (this.$refs.grid) {
      const gridComponent = this.$refs.grid as any
      gridComponent.excelExport()
    }
  }

  async downloadExcelSage(data: any[]) {
    const invoices = await this.fetchSageInvoices(data)
    const isSageExcelDownloaded = downloadExcelByArray(
      invoices,
      this.$t('files_name.invoice_sage_export').toString(),
      'Emitidas',
      false
    )

    if (!isSageExcelDownloaded) {
      this.openDialog({
        type: DialogTypes.ERROR,
        message: this.$t('components.alerts.customer_invoices_sage_error')
      })
    }
  }

  getSageFilterData(filters: any, archivedSelectedOption: any, selectedInvoices: any[]) {
    const allSelected = (this as any).$refs.grid.isAllSelectedValue()
    const ids = getArrayValuesByProp(selectedInvoices, 'id')
    const sageFilter: any = {...filters}
    if (allSelected) {
      sageFilter['archived'] = archivedSelectedOption
    }

    sageFilter['ids'] = !allSelected ? arrayValuesToString(ids): ''
    sageFilter['source'] = 'Emitidas'
    return sageFilter
  }
}
</script>
