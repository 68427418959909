<template>
  <div>
    <h1>DEFAULT COMPONENT</h1>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, onMounted } from 'vue'

const emit = defineEmits(['hide-tabs-spinner'])

// TODO ALE - Este componente es para pruebas. ELiminar cuando todo esté funcional
onMounted(() => {
  setTimeout(() => {
    emit('hide-tabs-spinner')
  }, 500)
})
</script>
