export enum ViewConfigTypes {
  VIEW = 'view',
  LIST = 'list'
}

export interface Configuration {
  type: ConfigurationTypes
  alias: ConfigurationAlias
  subType?: ConfigurationTypes
}

export interface ViewConfigurationType {
  [index: string]: object
}

export interface ViewConfiguration {
  id?: number
  name?: string
  alias: string
  config: {}
  type: ViewConfigTypes.LIST | ViewConfigTypes.VIEW
}

export interface ListConfigurationType {
  [alias: string]: object
}

export interface ConfigurationState {
  currentListConfiguration: object
  currentListConfigurationContext: ListConfigurationType
  currentViewConfiguration: ViewConfigurationType
  viewsConfiguration: ViewConfiguration[]
  customConfigText: string
  editingPeriodicBilling: boolean
  enabledPeriodicBillingTab: boolean
  lastListConfiguration: object
  relatedFormConfiguration: Array<{ config: string }>
  rememberUserCustomConfigurationList: boolean
  routeFromName: string
  userProfileConfiguration: object
  visiblePeriodicBillingTab: boolean
}

export interface ListConfiguration {
  columns: any[]
  filterSettings: []
  groupSettings: {}
  pageSettings: {
    currentPage: number
    pageSize: number
  }
  searchSettings: string
  sortSettings: []
}

export enum ConfigurationElements {
  WIDGET = '1',
  USER_PROFILE = '2',
  VIEW = '3',
  TAB = '4',
  INPUT = '5',
  DATE = '6',
  SWITCH = '7',
  LIST = '8',
  COMBO = '9',
  FORM = '10',
  CUSTOMER_PORTAL = '11',
  FILE_MANAGER = '12',
  TAB_HEADER = '13',
  EXPEDIENT_ACTIONS = '14',
  EXPEDIENT_ECONOMIC_DATA = '15',
  EXPEDIENT_INSURERS = '16',
  LEGAL_SEARCH = '17',
  INVOICES_TAB = '18',
  GRID = '19',
  EXPEDIENT_INVOICES = '20',
  CUSTOM_FIELDS_TAB = '22',
  CUSTOM_FIELDS_CONTAINER = '23',
  TANDEM_SEARCH = '24',
  EXPEDIENT_PERIODIC_BILLING = '26',
  TEMPLATE_GENERATOR = '27',
  EXPEDIENT_GLOBAL_VISION = '28',
  EXPEDIENT_AGENDA = '29',
  NUMERATION = '30',
  EXPEDIENT_COURTS = '31',
  EXPEDIENT_INTERVENERS = '32',
  USERS_MAINTENANCE = '33',
  USERS_MAINTENANCE_CUSTOM_PERMISSIONS = '34',
  FORM_ALERT = '35',
  EMAIL_TEMPLATES_CUSTOMER_PORTAL_TAB = '36',
  ALERTS_MESSAGES = '37',
  LFGRID = '38',
  SUB_ACTIONS = '39',
  CONTACT_CONTACS = '40',
  CONTACT_EXPEDIENTS = '41',
  CONTACT_ACTIONS = '42',
  CONTACT_PROVISIONS = '43',
  CONTACT_INVOICES = '44',
  CONTACT_BANKDRAFTS = '45',
  ACTION_CLASSIFICATION = '46',
  PROFESSIONAL_ACCESS = '47',
  EDIT_DOCUMENT_INTERVENER = '48',
  INVOICES_BANKDRAFT_TAB = '49',
  STATIC_ALERT = '50',
  SEARCH_EXPEDIENTS = '51',
  SEARCH_CONTACTS = '52',
  SEARCH_ACTIONS = '53',
  SEARCH_INVOICES = '54',
  SEARCH_DOCUMENTATIONS = '55',
  SEARCH_AGENDA = '56',
}

export enum ConfigurationTypes {
  CONFIGURATION = 'CONFIGURATION',
  PROFILE = 'PROFILE',
  VIEW = 'view'
}

export enum ConfigurationAlias {
  ACTION = 'actions',
  ACTION_CALLS = 'actionsCalls',
  ACTION_EMAILS = 'actionsEmails',
  ACTION_LEXNET = 'actionsLexnet',
  ACTION_MEETINGS = 'actionsMeetings',
  ACTION_OTHERS = 'actionsOthers',
  ACTION_PROCEDURES = 'actionsProcedures',
  BILLING_CUSTOMER_BANKDRAFTS = 'customerBankdrafts',
  BILLING_CUSTOMER_INVOICES = 'customerInvoices',
  BILLING_ORDER_FORMS = 'orderForms',
  BILLING_PROFORMA_INVOICES = 'proformaInvoices',
  BILLING_PROVIDER_BANKDRAFTS = 'providerBankdrafts',
  BILLING_PROVIDER_INVOICES = 'providerInvoices',
  BILLING_PROVISIONS = 'provisions',
  CONTACT = 'contact',
  CONTACT_ALL = 'all',
  COURT_FORM = 'courtForm',
  CUSTOMER_PANEL = 'customerPanel',
  DOCUMENT_PROPERTIES = 'documentsProperties',
  DOCUMENT_PROPERTIES_INTERVENERS = 'documentsPropertiesInterveners',
  EXPEDIENT = 'expedient',
  EXPEDIENT_COURTS = 'expedientCourt',
  EXPEDIENT_COURTS_FORM = 'expedientCourtForm',
  EXPEDIENT_INTERVENERS = 'expedientIntervener',
  EXPEDIENT_INTERVENERS_FORM = 'expedientIntervenerForm',
  EXPEDIENT_PERIODIC_BILLING_FORM = 'expedientPeriodicBillingForm',
  FOLDER_PROPERTIES = 'foldersProperties',
  MAINTENANCE = 'maintenance',
  MAINTENANCE_ACTIONS = 'maintenanceActions',
  MAINTENANCE_CONTACTS = 'maintenanceContacts',
  MAINTENANCE_CUSTOMER_PORTAL = 'maintenanceCustomerPortal',
  MAINTENANCE_CUSTOMER_PORTAL_TEMPLATES = 'manCustomerPortal',
  MAINTENANCE_DOCUMENTS = 'maintenanceDocuments',
  MAINTENANCE_EXPEDIENTS = 'maintenanceExpedients',
  MAINTENANCE_INVOICES = 'maintenanceInvoices',
  MAINTENANCE_USERS = 'maintenanceUsers',
  SEARCH = 'search',
  SUB_ACTION = 'subAction',
  SUB_ACTION_FORM = 'subActionsForm',
  TANDEM_FILTERS = 'tandemFilters'
}

export enum ListNames {
  ACTIONS_PANEL = 'listActionsPanel',
  ACTION_ALL = 'listActionsAll',
  ACTION_EMAILS = 'listActionsEmails',
  ACTION_LEXNET = 'listActionsLexnet',
  ACTION_OTHERS = 'listActionsOthers',
  APPOINTMENTS_REPORT = 'listInfCitComp',
  AUDIT = 'listAudit',
  AUDIT_PORTAL = 'listAuditPortal',
  CALLS = 'listActionsCalls',
  CONTACTS = 'listContacts',
  CUSTOMER_BANKDRAFTS = 'listBankdraftsCustomers',
  CUSTOMER_BANKDRAFTS_DETAILS = 'listBankdraftsCustomersDetails',
  CUSTOMER_CONTACS = 'listCustomerContacts',
  CUSTOMER_EXPEDIENTS = 'listCustomerExpedients',
  CUSTOMER_INVOICES = 'listInvoicesCustomers',
  CUSTOMER_INVOICES_DETAILS = 'listCustomersInvoicesDetails',
  CUSTOMER_PORTAL_TEMPLATES_MAINTENANCE = 'listManCustomerPortal',
  CUSTOMER_PROFORMA_INVOICES = 'listCustomerProformaInvoices',
  CUSTOMER_PROVISIONS = 'listCustomerProvisions',
  EXPEDIENTS = 'listExpedients',
  EXPEDIENT_ACTIONS = 'listExpedientsActions',
  FILE_MANAGER = 'listFileManager',
  FILE_MANAGER_ALL = 'listFileManagerAll',
  FILE_MANAGER_TEMPLATE = 'listFileManagerTemplate',
  HISTORY = 'listExpedientHistory',
  LIST_CUSTOMER_INVOICES = 'listCustomerInvoices',
  MEETINGS = 'listActionsMeetings',
  ORDER_FORMS = 'listOrderForm',
  OTHERS = 'listOther',
  PROCEDURES = 'listActionsProcedures',
  PROFESSIONALS = 'listManProfessionals',
  PROFORMA_INVOICES = 'listInvoicesProforma',
  PROFORMA_INVOICES_DETAILS = 'listProformaInvoicesDetails',
  PROVIDER_BANKDRAFTS = 'listBankdraftsProviders',
  PROVIDER_BANKDRAFTS_DETAILS = 'listBankdraftsProvidersDetails',
  PROVIDER_INVOICES = 'listInvoicesProviders',
  PROVIDER_INVOICES_DETAILS = 'listProvidersInvoicesDetails',
  PROVISIONS = 'listProvisions',
  REPORTS = 'listReports',
  REPORTS_LIST = 'listInfGet',
  SIGN_AUDIT = 'listSignAudit',
  USERS = 'listUsers'
}

export enum companyConfigTypes {
  DOCUMENT_ALLOWED_EXTENSIONS = 'document_allowed_extensions',
  DOCUMENT_RESTRICT_EXTENSIONS = 'document_restrict_extensions',
  EXPEDIENTS_GRID_VERSION = 'expedients_grid_version',
  MAIL_APP_VERSION = 'mail_app_version',
  REPORTS_GRID_VERSION = 'reports_grid_version'
}

export const configurationMutationTypes = {
  ENABLE_OR_DISABLE_ONE_TAB: 'ENABLE_OR_DISABLE_ONE_TAB',
  ENABLE_OR_DISABLE_TAB: 'ENABLE_OR_DISABLE_TAB',
  FETCH_RELATED_FORM_FIELDS: 'FETCH_RELATED_FORM_FIELDS',
  FETCH_USER_PROFILE_CONFIGURATION: 'FETCH_USER_PROFILE_CONFIGURATION',
  SET_CURRENT_LIST_CONFIGURATION_CONTEXT: 'SET_CURRENT_LIST_CONFIGURATION_CONTEXT',
  GET_CURRENT_VIEW_CONFIGURATION: 'GET_CURRENT_VIEW_CONFIGURATION',
  REMEMBER_USER_CUSTOM_CONFIGURATION_LIST: 'REMEMBER_USER_CUSTOM_CONFIGURATION_LIST',
  RESET_CURRENT_LIST_CONFIGURATION: 'RESET_CURRENT_LIST_CONFIGURATION',
  RESET_CURRENT_VIEW_CONFIGURATION: 'RESET_CURRENT_VIEW_CONFIGURATION',
  RESET_CUSTOM_CONFIG_TEXT: 'RESET_CUSTOM_CONFIG_TEXT',
  RESET_EDITING_PERIODIC_BILLING: 'RESET_EDITING_PERIODIC_BILLING',
  RESET_VIEW_CONFIGURATION: 'RESET_VIEW_CONFIGURATION',
  SET_CURRENT_LIST_CONFIGURATION: 'SET_CURRENT_LIST_CONFIGURATION',
  SET_CURRENT_VIEW_CONFIGURATION: 'SET_CURRENT_VIEW_CONFIGURATION',
  SET_CUSTOM_CONFIG_TEXT: 'SET_CUSTOM_CONFIG_TEXT',
  SET_EDITING_PERIODIC_BILLING: 'SET_EDITING_PERIODIC_BILLING',
  SET_LAST_LIST_CONFIGURATION: 'SET_LAST_LIST_CONFIGURATION',
  SET_ROUTE_FROM_NAME: 'SET_ROUTE_FROM_NAME',
  SET_VIEW_CONFIGURATION: 'SET_VIEW_CONFIGURATION',
  SHOW_OR_HIDE_ONE_TAB: 'SHOW_OR_HIDE_ONE_TAB'
}

export enum ConfigurationAppointmentVersion {
  INTERNAL_VERSION = 1,
  LEXBOX_VERSION = 2
}
