<script lang="ts">
import { Action, Getter } from 'vuex-class'
import { Component } from 'vue-property-decorator'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { ContactPersonTypeId } from '@/store/modules/contacts/contactsTypes'
import { ContactFields, NaturalFields, LegalFields } from './types/ContactFieldTypes'
import TabsView from '@/views/Tabs/TabsView.vue'

const formsModule: string = ModuleNamespaces.FORMS
const entitiesModule: string = ModuleNamespaces.ENTITIES

@Component
export default class ContactDetailView extends TabsView {
  @Action('saveSelectedEntityName', { namespace: entitiesModule })
  saveSelectedEntityName: (type: string) => void

  @Action('prepareRegisterFormDataToSave', { namespace: formsModule })
  prepareFormData: ({}) => void

  @Getter('getRegisterFormData', { namespace: formsModule })
  getFormData: () => object

  contactPersonTypeName = `${this.$t('routes.new_f')} ${this.$t('navigation.drawer-menu-items.person')}`

  texts = {
    nameNatural: this.$t('components.contact.name_natural'),
    nameLegal: this.$t('components.contact.name_legal')
  }
  created() {
    this.saveSelectedEntityName(this.getContext)
  }

  async onInitForm(params: any) {
    const { schema, isNewForm } = params
    this.isNewRegister = isNewForm
    this.formLoaded = true
    await this.disableFieldInFieldset(ContactFields.PROFESSIONAL_TYPE, ContactFields.CHARTERED_FIELDS, schema, true)
  }

  get breadcrumbsText() {
    if (this.getSelectedRegisterData) {
      return this.getSelectedRegisterData.name
        ? this.getSelectedRegisterData.name + ' ' + (this.getSelectedRegisterData.firstSurname || '')
        : this.contactPersonTypeName.charAt(0).toUpperCase() + this.contactPersonTypeName.slice(1).toLowerCase()
    }
    return ''
  }

  onFieldChanged(schema: any, formData: any, field: any, value: any) {
    if (ContactFields.PERSON_TYPE === field.id) {
      const isNatural = value === ContactPersonTypeId.NATURAL
      const nameItem: any = schema.find((item: any) => item.id === ContactFields.NAME)
      if (nameItem) {
        nameItem.label = isNatural ? this.texts.nameNatural : this.texts.nameLegal
      }
      LegalFields.forEach((item) => this.changehidden(item, schema, isNatural))
      NaturalFields.forEach((item) => this.changehidden(item, schema, !isNatural))
      this.changehiddenFieldSet(ContactFields.CHARTERED_FIELDS, schema, !isNatural)
      this.changehiddenFieldSet(
        ContactFields.CHARTERED_EXTRA_FIELDS,
        schema,
        isNatural ? !formData[ContactFields.PROFESSIONAL_TYPE] : true
      )
    }
    if (ContactFields.CHARTERED === field.id) {
      const disabled = '0' === value
      this.disableFieldInFieldset(ContactFields.PROFESSIONAL_TYPE, ContactFields.CHARTERED_FIELDS, schema, disabled)
      this.changehiddenFieldSet(
        ContactFields.CHARTERED_EXTRA_FIELDS,
        schema,
        formData[ContactFields.PROFESSIONAL_TYPE] === undefined || disabled
      )
    }
    if (ContactFields.PROFESSIONAL_TYPE === field.id) {
      const hidden = null === value
      this.changehiddenFieldSet(ContactFields.CHARTERED_EXTRA_FIELDS, schema, hidden)
    }
    if (ContactFields.UNNUMBERED === field.id) {
      const disabled = '1' === value
      const locationFields = schema.find((item: any) => {
        return item.fieldsetId === ContactFields.LOCATION_FIELDS
      })
      this.disableField(ContactFields.NUMBER, locationFields.fields, disabled)
    }
  }

  changehidden(fieldId: string, schema: any, hidden: boolean) {
    const field = schema.find((item: any) => {
      return item.id === fieldId
    })
    field.hidden = hidden
  }

  disableField(fieldId: string, schema: any, disabled: boolean) {
    const field = schema.find((item: any) => {
      return item.id === fieldId
    })
    field.disabled = disabled
  }

  disableFieldInFieldset(fieldId: string, fieldSet: string, schema: any, disabled: boolean) {
    const fieldsInFieldset = schema.find((item: any) => {
      return item.fieldsetId === fieldSet
    })
    fieldsInFieldset.fields.map((item: any) => {
      if (item.id === fieldId) {
        item.disabled = disabled
      }
      return item
    })
  }

  changehiddenFieldSet(fieldSet: string, schema: any, hidden: boolean) {
    const fieldsInFieldset = schema.find((item: any) => {
      if (item.fieldsetId === fieldSet) {
        item.hidden = hidden
      }
      return item.fieldsetId === fieldSet
    })
    fieldsInFieldset.fields.map((item: any) => (item.hidden = hidden))
  }

  async save(redirectToGridTable = true, resolve: any = null, _idObjectType = '') {
    const formData: any = this.getFormData
    this.prepareFormData(formData)

    const { type } = this.entity(this.getContext)
    const { canSave } = this.checkEntityPermissionsGetter(type)
    if (canSave) {
      await this.saveRegisterFormData({
        endpoint: this.endpoints(this.entity(this.getContext).type).save,
        idSelectedRegister: this.entity(this.getContext).id,
        goToURL: redirectToGridTable ? this.reviewRedirect() : null,
        context: this.getContext
      })
    }

    if (resolve) {
      resolve()
    }
  }
}
</script>
