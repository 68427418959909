<template>
  <v-menu bottom left nudge-bottom="42" nudge-right="18" content-class="custom-context-menu">
    <template v-if="parsedButtons.length > 0" v-slot:activator="{ on, attrs }">
      <button class="context-menu-kebab-btn" v-bind="attrs" v-on="on">
        <span :class="kebabIcon" class="lf-icon__lg" />
      </button>
    </template>

    <v-list class="context-menu-list">
      <v-list-item class="list-item" v-for="({ action, tooltip, icon, enabled }, i) in parsedButtons" :key="i">
        <button class="button" @click="executeAction(action)" :disabled="!enabled">
          <span :class="icon" class="lf-icon__lg" />
          <span class="text">{{ tooltip }}</span>
        </button>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { Icons } from '@/icons/icons'
import { ActionBarButton } from './types/ActionBarComponentTypes'

// PROPS

const props = defineProps({
  buttons: {
    type: Array as PropType<ActionBarButton[]>,
    required: true
  }
})

// DATA
const kebabIcon = Icons.KEBAB

// EVENTS
const emit = defineEmits(['execute-action'])

const parsedButtons = computed(() => props.buttons.filter((button: ActionBarButton) => !button.hidden))

// METHODS
function executeAction(action: string) {
  emit('execute-action', action)
}
</script>

<style lang="scss" scoped>
.context-menu-kebab-btn {
  color: $main-1000;
  margin-left: $spacing-sm;
}
</style>

<style lang="scss">
.custom-context-menu {
  @include context-menu;
  --context-menu-width: 210px;

  .context-menu-list,
  .button {
    @include flex($justify-content: flex-start);
    width: 100%;
    text-align: left;
    color: $neutral-grey-800;
  }
  .button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .context-menu-list {
    flex-direction: column;
  }

  .list-item {
    height: $input-size;
    min-height: $input-size;
    max-height: $input-size;
    width: 100%;
    padding: 0 $spacing-xxs;
    cursor: pointer;

    &:hover {
      background-color: $main-050;
    }

    ~ .list-item {
      margin-top: $spacing-xxs;
    }

    .text {
      margin-left: $spacing-xxs;
    }
  }
}
</style>
