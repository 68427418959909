<template>
  <div class="breadcrumbs-container">
    <router-link :to="dashboardRoute" class="go-dashboard">
      <span :class="[homeIcon, 'lf-icon__md']"></span>
    </router-link>
    <span class="separator">></span>
    <div v-for="(item, index) in items" :key="index" :class="['breadcrumb', { 'to-lower-case': item.lowerCase }]">
      <router-link v-if="item.url" :to="item.url" class="breadcrumb-link">
        {{ replaceDash(item.text) }}
      </router-link>
      <span v-else class="breadcrumb-text">
        {{ replaceDash(item.text) }}
      </span>
      <span v-if="index + 1 < numberOfItems" class="separator">></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, PropType, ref } from 'vue'
import { Icons } from '@/icons/icons'
import { URLS } from '@/router/routes/urlRoutes'

const props = defineProps({
  items: {
    type: Array as PropType<any[]>,
    required: true
  }
})

const homeIcon = Icons.HOME

const numberOfItems = ref(props.items.length)

function replaceDash(value: string) {
  return value.replace(/-/g, ' ')
}

const dashboardRoute = `/${URLS.DASHBOARD}`
</script>

<style scoped lang="scss">
.breadcrumbs-container {
  @include flex($justify-content: flex-start);
  width: 100%;
  height: 26px;
  color: $neutral-grey-800;
  gap: $spacing-xxs;

  .go-dashboard {
    color: $neutral-grey-800;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    top: -2px;
  }

  .breadcrumb {
    @include flex;
    @include milano-regular-16;
    gap: $spacing-xxs;

    .breadcrumb-text {
      @include milano-regular-16;
      @include ellipsis;
      max-width: 300px;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .breadcrumb-link {
    color: $neutral-grey-800;
    text-decoration: none;
    margin-left: $spacing-xxs;

    &::first-letter {
      text-transform: capitalize;
    }

    ~ .separator {
      margin-left: $spacing-xxs;
    }
  }

  .separator {
    margin: 0 $spacing-xxs;
  }
}
</style>
