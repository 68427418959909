<template lang="pug">

v-tooltip(
    class="lexon-tooltip-container"
    v-bind="tooltipPosition"
    :content-class="positionClass"
  )
  template(v-slot:activator="{ on, attrs }")
    div(
      class="activator"
      v-bind="attrs"
      v-on="on"
    )
      slot(name="activator-element")
        span(
          :class="[icon, 'tooltip-icon']"
          @click="action"
        )
  p(class="lefebvre-tooltip-message") {{ message }}

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class LexonTooltipComponent extends Vue {
  @Prop({
    type: String
  })
  position!: 'top' | 'left' | 'bottom' | 'right'

  @Prop({
    type: Function,
    default: () => {}
  })
  action!: () => void

  @Prop({
    type: String,
    default: Icons.INFO
  })
  icon!: string

  @Prop({
    type: String,
    required: true
  })
  message!: string

  @Prop({
    type: Boolean,
    default: false
  })
  isWidthAuto!: boolean

  get positionClass() {
    switch (this.position) {
      case 'top':
        return `lexon-tooltip-component--top ${this.isWidthAuto ? 'width-auto' : ''}`
      default:
        return `lexon-tooltip-component ${this.isWidthAuto ? 'width-auto' : ''}`
    }
  }

  get tooltipPosition() {
    switch (this.position) {
      case 'top':
        return { top: true }
      case 'bottom':
        return { bottom: true }
      case 'left':
        return { left: true }
      default:
        return { right: true }
    }
  }
}
</script>

<style lang="scss" scoped>
.activator {
  align-self: var(--activator-align-self, stretch);
  line-height: var(--activator-line-height, 14px);
  overflow: hidden;
}

.tooltip-icon {
  font-size: 18px;
  padding-top: 2px;
  margin-left: 10px;
  cursor: pointer;
}

.lefebvre-tooltip-message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}
</style>

<style lang="scss">
@mixin shared {
  opacity: 1 !important;
  z-index: 9999 !important;
  display: flex;
  max-width: 200px;
  padding: $spacing-xs;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-xxs;
  border-radius: $cornerRadius-sm;
  background: $main-050;

  &.width-auto {
    max-width: 100%;
  }

  .lefebvre-tooltip-message {
    @include milano-regular-14;
    opacity: 1 !important;
    padding: 10px;
    margin-bottom: 0 !important;
    color: $neutral-grey-800;
  }
}

.v-tooltip__content.lexon-tooltip-component {
  @include shared;

  &--top {
    @include shared;
    filter: drop-shadow(0 2px 4px rgba(113, 113, 113, 0.25));

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -20px;
      left: 50%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid $main-050;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      transform: translateX(-50%);
    }
  }
}
</style>
