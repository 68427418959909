import { MutationTree } from 'vuex'
import {
  AlertsMessagesState,
  AlertMessageList, defaultList, ListStatus
} from '@/store/modules/alertsMessages/alertsMessagesTypes'

export const mutations: MutationTree<AlertsMessagesState> = {
  UPDATE_ALERTS_MESSAGES(state:AlertsMessagesState, alertsMessagesList: AlertMessageList) {
    state.alertsMessagesList = alertsMessagesList
  },
  UPDATE_FILTERED_LIST(state:AlertsMessagesState, alertsMessagesList: AlertMessageList) {
    state.filteredList = alertsMessagesList
  },
  SET_FILTER(state:AlertsMessagesState, filter) {
    state.filter = {
      ...state.filter,
      ...filter,
    }
  },
  SET_LOAD_ALERT_MESSAGES_LIST_ERROR(state:AlertsMessagesState) {
    state.alertsMessagesList = {
      ...defaultList,
      status: ListStatus.ERROR
    }
  },
  SET_LOAD_FILTERED_LIST_ERROR(state:AlertsMessagesState) {
    state.filteredList = {
      ...defaultList,
      status: ListStatus.ERROR
    }
  },
  RESET_ALERT_MESSAGES_LIST(state:AlertsMessagesState) {
    state.alertsMessagesList = {
      ...defaultList,
    }
  },
  RESET_FILTERED_LIST(state:AlertsMessagesState) {
    state.filteredList = {
      ...defaultList,
    }
  },
  SET_HAS_ALERTS(state:AlertsMessagesState, hasAlert: boolean) {
    state.hasAlert = hasAlert
  }
}
