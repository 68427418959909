<template>
  <div class="form-container">
    <div :class="['form', { 'api-error': apiError }]">
      <ValidationObserver ref="form" class="login-form" tag="form" @submit.prevent="login">
        <TextFieldComponent
          v-if="isLoginView || isRememberPasswordView"
          class="input-container"
          validationRules="required|email"
          :prependIcon="icons.mail"
          :placeholder="text.email"
          id="email"
          v-model.trim="credentials.email"
        />
        <TextFieldComponent
          v-if="isLoginView"
          class="input-container"
          validationRules="required"
          id="password"
          :prependIcon="icons.lock"
          :appendIcon="icons.visible"
          :placeholder="text.password"
          type="password"
          v-model.trim="credentials.password"
        />
        <StaticAlertComponent v-if="apiError" :alert="alert" class="static-alert"></StaticAlertComponent>
        <RememberCheckboxComponent />
        <button type="submit" name="submit" class="login-button">Acceder</button>
      </ValidationObserver>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { Company, Credentials } from '@/store/modules/auth/authTypes'
import { URLS } from '@/router/routes/urlRoutes'
import { Icons } from '@/icons/icons'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'
import useRouter from '@/composables/useRouter'
import { useI18n } from 'vue-i18n-composable'
import TextFieldComponent from '@/components/TextField/TextFieldComponent.vue'
import RememberCheckboxComponent from '@/components/forms/loginForm/RememberCheckboxComponent.vue'
import StaticAlertComponent from '@/components/Alert/StaticAlertComponent.vue'
import { ValidationObserver } from 'vee-validate'
import { AlertsData, AlertsTypes } from '@/store/modules/alerts/alertsTypes'

const authNamespace: string = ModuleNamespaces.AUTH
const errorsNamespace: string = ModuleNamespaces.ERROR

const icons = {
  mail: Icons.EMAIL,
  lock: Icons.LOCK,
  visible: Icons.VISIBLE
}

// ROUTER
const { route, goToRoute, goToDashboard } = useRouter()

// TRANSLATIONS
const { t } = useI18n()
const text = {
  password: t('views.login.password') as string,
  email: t('views.login.email') as string
}

// GETTERS
const apiError = computed<string>(() => store.getters[`${errorsNamespace}/getError`])
const companies = computed<Company[]>(() => store.getters[`${authNamespace}/getCompanies`])

// DATA
const credentials = reactive<Credentials>({
  email: '',
  password: ''
})
const form = ref()

// COMPUTED
const isLoginView = computed<boolean>(() => {
  return route?.name === URLS.LOGIN
})
const isRememberPasswordView = computed<boolean>(() => {
  return route?.name === URLS.REMEMBER_PASSWORD
})
const alert = computed<AlertsData>(() => {
  return {
    type: AlertsTypes.ERROR,
    message: t(apiError.value)
  }
})

// METHODS
function clearApiError(): void {
  store.dispatch(`${errorsNamespace}/clearError`)
}

async function fetchUserData(credentials: Credentials) {
  return await store.dispatch(`${authNamespace}/login`, credentials)
}

async function selectCompany(id: number) {
  await store.dispatch(`${authNamespace}/selectCompany`, id)
}

async function login() {
  clearApiError()
  const formIsValid = await form.value.validate()

  if (formIsValid) {
    await fetchUserData(credentials)

    if (!apiError.value) {
      if (companies.value && companies.value.length > 1) {
        goToRoute({
          name: URLS.SELECT_COMPANY,
          params: { credentials: JSON.stringify(credentials) }
        })
      } else {
        const companyId = companies.value[0].idCompany
        const companyName = companies.value[0].name
        await selectCompany(companyId)
        if (!apiError.value) {
          localStorage.setItem('company-name', companyName)
          goToDashboard()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-lg;
  align-self: stretch;

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;

      ::v-deep(.prepend-icon) {
        &::after {
          content: '';
          width: 1px;
          height: 20px;
          background-color: $neutral-grey-400;
          margin-left: 6px;
        }
      }

      .login-button {
        @include poppins-regular-14;
        color: $neutral-white-000;
        display: flex;
        padding: $spacing-xs $spacing-lg;
        align-items: center;
        align-self: flex-end;
        gap: $spacing-xxs;
        border-radius: $cornerRadius-100;
        background: $secondary-pumpkin-200;
        margin-top: $spacing-sm;

        &:hover {
          background-color: $secondary-pumpkin-100;
        }
      }
    }

    &.api-error ::v-deep(.input-field) {
      border-color: $error-color;

      .prepend-icon::after {
        background-color: $error-color;
      }
    }

    .static-alert {
      margin-bottom: $spacing-sm;
    }
  }
}
</style>
