import RememberPasswordView from '@/views/RememberPassword/RememberPasswordView.vue'
import { URLS } from '@/router/routes/urlRoutes'
import { to, from, next } from '@/router/types/routerTypes'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import store from '@/store/store'
import { router } from '@/router/router'
import { Route } from 'vue-router'

export const loginRoutes = [
  {
    path: `/${URLS.LOGIN}`,
    name: URLS.LOGIN,
    component: () => import('@/views/Login/LoginView.vue'),
    meta: {
      title: URLS.LOGIN
    }
  },
  {
    path: `/${URLS.NAVISION_LOGIN}/:navisionToken`,
    name: URLS.NAVISION_LOGIN,
    beforeEnter: async (to: to, _from: from, _next: next<any>) => {
      await store.dispatch(`${ModuleNamespaces.AUTH}/navisionLogout`)

      await store.dispatch(`${ModuleNamespaces.AUTH}/navisionLogin`, to.params.navisionToken)
      const userIsPreAuthenticated = store.getters[`${ModuleNamespaces.AUTH}/userIsPreAuthenticated`]
      const companies = store.getters[`${ModuleNamespaces.AUTH}/getCompanies`]

      if (userIsPreAuthenticated) {
        if (companies && companies.length > 1) {
          router.push({ name: URLS.SELECT_COMPANY, params: { navisionToken: to.params.navisionToken } })
        } else {
          const companyId = companies[0].idCompany
          const companyName = companies[0].name
          await store.dispatch(`${ModuleNamespaces.AUTH}/selectCompany`, companyId)
          localStorage.setItem('company-name', companyName)
          router.push({ name: URLS.DASHBOARD })
        }
      } else {
        await store.dispatch(`${ModuleNamespaces.AUTH}/logout`)
      }
    }
  },
  {
    path: `/${URLS.SELECT_COMPANY}/:navisionToken?`,
    name: URLS.SELECT_COMPANY,
    props: true,
    component: () => import('@/views/SelectCompany/SelectCompanyView.vue'),
    meta: {
      title: URLS.SELECT_COMPANY
    }
  },
  {
    path: `/${URLS.REMEMBER_PASSWORD}`,
    name: URLS.REMEMBER_PASSWORD,
    component: RememberPasswordView,
    meta: {
      title: URLS.REMEMBER_PASSWORD
    }
  },
  {
    path: `/${URLS.DOUBLE_OPTIN}/:uuid`,
    name: URLS.DOUBLE_OPTIN,
    component: () => import('@/views/Login/DoubleOptinAdviseView.vue'),
    props: ({ params }: Route) => ({ uuid: params.uuid }),
    meta: {
      title: URLS.DOUBLE_OPTIN
    }
  },
]
