import { computed, ComputedRef, Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { Route } from 'vue-router'

interface UseHistoryStoreResult {
  history: Ref<History[]>
  route: ComputedRef<{
    current: History
    previous: History
  }>
  sameRoute: ComputedRef<boolean>
  rememberSearchView: ComputedRef<boolean>
  addHistory: (route: Route) => void
  removeHistory: (index: number) => void
  clearHistory: () => void
  getLastHistory: ComputedRef<History>
}

interface History {
  name: string
  path: string
  query?: string
}

const MAX_HISTORY_LENGTH = 5

export const useHistoryStore = defineStore('history', (): UseHistoryStoreResult => {
  const history = ref<History[]>([])

  function addHistory (route: Route) {
    if (history.value.length >= MAX_HISTORY_LENGTH) {
      history.value.shift()
    }

    history.value.push({
      name: route.name as string,
      path: route.path
    })
  }

  function removeHistory (index: number) {
    history.value.splice(index, 1)
  }

  function clearHistory () {
    history.value = []
  }

  const getLastHistory = computed(() => history.value[history.value.length - 2])

  const route = computed(() => ({
    current: history.value[history.value.length - 1],
    previous: history.value[history.value.length - 2]
  }))

  const sameRoute = computed(() => route.value.current?.name === route.value.previous?.name)

  const rememberSearchView = computed(() => sameRoute.value || history.value?.[history.value.length - 3]?.path === route.value.current?.path)

  return {
    history,
    addHistory,
    removeHistory,
    clearHistory,
    getLastHistory,
    route,
    sameRoute,
    rememberSearchView
  }
})
