export enum Icons {
  ACTIONS = 'lf-icon-clock',
  ADD = 'lf-icon-sum',
  ADD_ROUND = 'lf-icon-add-round',
  ADD_ROUND_FULL = 'lf-icon-add-round-full',
  ADVANCE = 'lf-icon-advance',
  ALERT = 'lf-icon-notification',
  ALERT_O = 'lf-icon-clock',
  ANALYSIS = 'lf-icon-analysis',
  ANALYSIS_L = 'lf-icon-label-analysis',
  ANGLE_DOWN = 'lf-icon-angle-down',
  ANGLE_LEFT = 'lf-icon-angle-left',
  ANGLE_RIGHT = 'lf-icon-angle-right',
  ANGLE_UP = 'lf-icon-angle-up',
  ARCHIVE = 'lf-icon-archive',
  ARCHIVE_EMPTY = 'lf-icon-archive-empty',
  ARROW_DOWN = 'lf-icon-arrow-down',
  ARROW_UP = 'lf-icon-arrow-up',
  ATACH = 'lf-icon-add',
  BACK = 'lf-icon-back',
  BILL = 'lf-icon-bill',
  BILLING = 'lf-icon-money',
  BOX_ACTIVE = 'lf-icon-check-empty',
  BOX_INACTIVE = 'lf-icon-box-inactive',
  CALCULATOR = 'lf-icon-calculator',
  CALENDAR = 'lf-icon-calendar',
  CALENDAR_CHECK = 'lf-icon-calendar-check',
  CALL = 'lf-icon-call',
  CENTINELA = 'lf-icon-centinela-full',
  CERTIFIED_DOCUMENT = 'lf-icon-certified-document',
  CERTIFIED_EMAIL = 'lf-icon-certified-mail',
  CERTIFIED_SMS = 'lf-icon-certified-sms',
  CHAT = 'lf-icon-chat',
  CHECK = 'lf-icon-check',
  CHECK_FULL = 'lf-icon-check-full',
  CHECK_ROUND = 'lf-icon-check-round',
  CHECK_ROUND_FULL = 'lf-icon-check-round-full',
  CIRCLE_FULL = 'lf-icon-step-final',
  CIRCLE_O = 'lf-icon-step-first',
  CLIENT_LINK='lf-icon-clientlink',
  CLOCK = 'lf-icon-clock',
  CLOSE = 'lf-icon-close',
  CLOSE_DATE = 'lf-icon-close-date',
  CLOSE_EMPTY = 'lf-icon-close-empty',
  CLOSE_FULL = 'lf-icon-close-full',
  CLOSE_ROUND = 'lf-icon-close-round',
  CLOSE_ROUND_FULL = 'lf-icon-close-round-full',
  CLOSE_SEARCH = 'lf-icon-close-search',
  CLOSE_SESSION = 'lf-icon-close-session',
  CODE = 'lf-icon-code',
  COIN = 'lf-icon-coin',
  COLLAPSE = 'lf-icon-collapse-panel',
  COMPANY ='lf-icon-company',
  CONFIGURATION = 'lf-icon-configuration',
  CONTACTS = 'lf-icon-contacts',
  COPY = 'lf-icon-copy',
  CORNER = 'lf-icon-corner-text',
  CSV = 'lf-icon-document',
  CUT = 'lf-icon-cut',
  DANGER = 'lf-icon-danger',
  DATABASE = 'lf-icon-qmemento',
  DOCUMENTATION = 'lf-icon-document',
  DOCUMENTS = 'lf-icon-documents',
  DOCUMENT_ATTACHMENT = 'lf-icon-add',
  DOCUMENT_VALIDATE = 'lf-icon-document-validate',
  DOUBLE_ARROW_LEFT = 'lf-icon-double-arrow-left',
  DOUBLE_ARROW_RIGHT = 'lf-icon-double-arrow-right',
  DOUBLE_LOCK = 'lf-icon-double-lock',
  DOWNLOAD = 'lf-icon-download',
  DROPDOWN = 'lf-icon-dropdown',
  EDIT = 'lf-icon-edit',
  EDIT_OWN = 'lf-icon-edit-own',
  EMAIL = 'lf-icon-mail',
  EXCEL = 'lf-icon-excel',
  EXPEDIENT = 'lf-icon-law',
  FACEBOOK = 'lf-icon-facebook-round',
  FILTER = 'lf-icon-filter-1',
  FILTER_APPLIED = 'lf-icon-filter-applied',
  FILTER_DELETE = 'lf-icon-filter-delete',
  FOLDER = 'lf-icon-folder',
  FOLDER_NEW = 'lf-icon-folder-new',
  FOLDER_OPEN = 'lf-icon-folder-open',
  FORMALITY = 'lf-icon-practical-cases',
  FORMS = 'lf-icon-forms',
  HANDSHAKE = 'lf-icon-handshake',
  HAND_SPHERE = 'lf-icon-hand-sphere',
  HELP = 'lf-icon-help',
  HOME = 'lf-icon-home',
  HOURGLASS = 'lf-icon-hourglass',
  IA = 'lf-icon-ia',
  IMPORTANT = 'lf-icon-important',
  INFO = 'lf-icon-information',
  INFO_FULL = 'lf-icon-info-full',
  INSTAGRAM = 'lf-icon-instagram-round',
  INTELLECTUAL = 'lf-icon-intellectual',
  INTERNAL_MANAGEMENT = 'lf-icon-task',
  IVA = 'lf-icon-iva',
  KEBAB = 'lf-icon-kebab-menu',
  LEAD = 'lf-icon-lead',
  LEARN = 'lf-icon-learn',
  LEFEBVRE = 'lf-icon-lefebvre',
  LESS = 'lf-icon-less',
  LESS_ROUND = 'lf-icon-less-round',
  LEXNET = 'lf-icon-lexnet',
  LEXON = 'lf-icon-lexon-full',
  LINK = 'lf-icon-link',
  LINKEDIN = 'lf-icon-linkedin-round',
  LOCK = 'lf-icon-lock',
  MAGAZINE = 'lf-icon-magazine',
  MAP_MARKER = 'lf-icon-map-marker',
  MEETING = 'lf-icon-meeting',
  MEMENTOS = 'lf-icon-mementos',
  MENU_LIST = 'lf-icon-list-menu',
  MODEL = 'lf-icon-model',
  MONEY_BAG = 'lf-icon-money-bag',
  MONEY_LOCALIZATION = 'lf-icon-money-localization',
  NEWS = 'lf-icon-news-communication',
  NEWSLETTER = 'lf-icon-newsletter',
  NEWSN = 'lf-icon-news',
  NOT_VISIBLE = 'lf-icon-not-visible',
  NUMBER_TEN = 'lf-icon-number-ten',
  OPEN_WINDOW = 'lf-icon-open-window',
  OPTIONS = 'lf-icon-configuration',
  OTHERS_F = 'lf-icon-documentation',
  PANEL = 'lf-icon-album-menu',
  PASTE = 'lf-icon-paste',
  PAUSE = 'lf-icon-pause',
  PDF = 'lf-icon-pdf',
  PENCIL = 'lf-icon-pencil',
  PERIODIC_COM = 'lf-icon-periodic-communication',
  PERIODIC_INSTANCE = 'lf-icon-periodic-instance',
  PLAY = 'lf-icon-play',
  PPT = 'lf-icon-ppt',
  PRICE = 'lf-icon-price',
  PRINT = 'lf-icon-print',
  PROCEDURES = 'lf-icon-practical-cases',
  PROCESS_STEP = 'lf-icon-process-step',
  PRODUCTS = 'lf-icon-products',
  QUESTION = 'lf-icon-question',
  RADIO_ACTIVE = 'lf-icon-radio-button-active',
  RECTIFY_CUSTOMER_INVOICE = 'lf-icon-simulator',
  REFRESH = 'lf-icon-refresh',
  REMOVE = 'lf-icon-trash',
  REMOVE_OWN = 'lf-icon-eliminate-its-own',
  RENAME = 'lf-icon-rename',
  REPORT = 'lf-icon-report',
  REPORTS = 'lf-icon-report',
  REPORTS_T = 'lf-icon-transparency-report',
  REVIEW = 'lf-icon-review',
  RRHH = 'lf-icon-search-rrhh',
  SAD_FACE = 'lf-icon-face-sad',
  SAVE = 'lf-icon-save',
  SAVE_AS = 'lf-icon-save-as',
  SEARCH = 'lf-icon-search',
  SEE = 'lf-icon-see',
  SEE_OWN = 'lf-icon-see-own',
  SEND_EMAIL = 'lf-icon-send-mail',
  SETTINGS = 'lf-icon-settings',
  SHARE = 'lf-icon-share',
  SHOPPING_CART= 'lf-icon-shopping-cart',
  SIGNATURE = 'lf-icon-signature',
  SIGNATURE_CERTIFICATE = 'lf-icon-signature-certificate',
  SPECIAL_AREA = 'lf-icon-special-area',
  SPINNER = 'lf-icon-spinner',
  STAGE = 'lf-icon-connect',
  STOP = 'lf-icon-stop',
  SUPPORT = 'lf-icon-support',
  SWITCH_OFF = 'lf-icon-switch-left',
  SWITCH_ON = 'lf-icon-switch-right',
  TABLE_DISPLAY = 'lf-icon-table-display',
  TABLE_DISPLAY_1 = 'lf-icon-table-display-1',
  TABLE_DISPLAY_2 = 'lf-icon-table-display-2',
  TAG = 'lf-icon-tag',
  TASK = 'lf-icon-label-task',
  TASKS = 'lf-icon-notes',
  TEMPLATE = 'lf-icon-document',
  TEXT_EDIT = 'lf-icon-text-edit',
  TEXT_FIELD = 'lf-icon-text-field',
  TRACK_RIGHT = 'lf-icon-track-right',
  TWITTER = 'lf-icon-twitter-round',
  UNLINK = 'lf-icon-unlink',
  UNLOCK = 'lf-icon-unlock',
  UPLOAD = 'lf-icon-up-file',
  USER = 'lf-icon-user',
  VIDEO = 'lf-icon-video',
  VISIBLE = 'lf-icon-visible',
  WARNING = 'lf-icon-warning',
  WARNING_FULL = 'lf-icon-warning-full',
  WORD = 'lf-icon-word',
  WORD_APP = 'f-icon-word-software',
  YOUTUBE = 'lf-icon-youtube-round',
  ZIP = 'lf-icon-zip'
}
