export interface RootState {
  [x: string]: any
  version: string
}

export enum ModuleNamespaces {
  ACTIONS = 'actionsModule',
  SUB_ACTIONS = 'subActionsModule',
  AGENCIES = 'agenciesModule',
  ALERTS = 'alertsModule',
  ALERTS_CONFIGURATION = 'alertsConfigurationModule',
  ALERTS_MESSAGES = 'alertsMessagesModule',
  APPOINTMENTS = 'appointmentsModule',
  AUDITS = 'auditsModule',
  AUTH = 'authModule',
  BILLING = 'billingModule',
  CONFIGURATION = 'configurationModule',
  CONFIGURATION_PANEL = 'configurationPanelModule',
  CONTACTS = 'contactsModule',
  CONTEXT_MENU = 'contextMenuModule',
  CUSTOMER_PORTAL = 'customerPortalModule',
  CUSTOM_FIELDS = 'customFieldsModule',
  DASHBOARD_WIDGETS = 'dashboardWidgetsModule',
  DIALOG = 'dialogModule',
  DOCUMENT_ONLINE = 'documentOnlineModule',
  ENDPOINT = 'endpointModule',
  ENTITIES = 'entitiesModule',
  ERROR = 'errorsModule',
  EXPEDIENTS = 'expedientsModule',
  FILE_MANAGER = 'fileManagerModule',
  FIRMA = 'firmaModule',
  FORMS = 'formsModule',
  GLOBAL = 'globalModule',
  INVOICES = 'invoicesModule',
  LEGAL_SEARCH = 'legalSearchModule',
  LEXBOX = 'lexboxModule',
  LIST_ITEMS = 'listItemsModule',
  MAINTENANCE = 'maintenanceModule',
  MENUS = 'menusModule',
  NOTES = 'notesModule',
  NOTIFICATIONS = 'notificationsModule',
  NUMERATIONS = 'numerationsModule',
  PERMISSIONS = 'permissionsModule',
  PROFESSIONALS = 'professionalsModule',
  REPORTS = 'reportsModule',
  ROUTER = 'routerModule',
  SEARCH = 'searchModule',
  SECURITY = 'securityModule',
  SELECTED_REGISTER = 'selectedRegisterModule',
  SPINNER = 'spinnerModule',
  TABS = 'tabsModule',
  TANDEM_SEARCH = 'tandemSearchModule',
  TASKS = 'tasksModule',
  TEMPLATE = 'templateModule',
  USERS = 'usersModule'
}

export enum ContextName {
  ACTION = 'action',
  ACTION_CALLS = 'actionCalls',
  ACTION_EMAILS = 'actionEmails',
  ACTION_LEXNET = 'actionLexnet',
  ACTION_MEETINGS = 'actionMeetings',
  ACTION_OTHERS = 'actionOthers',
  ACTION_PROCEDURES = 'actionProcedures',
  ALERTS_CONFIGURATION = 'alertsConfiguration',
  ALERTS_MESSAGES = 'alertsMessages',
  AUDITS = 'audits',
  BILLING = 'billing',
  BILLING_CUSTOMER_BANKDRAFTS = 'customerBankdrafts',
  BILLING_CUSTOMER_INVOICES = 'customerInvoices',
  BILLING_ORDER_FORMS = 'orderForms',
  BILLING_PROFORMA_INVOICES = 'proformaInvoices',
  BILLING_PROVIDER_BANKDRAFTS = 'providerBankdrafts',
  BILLING_PROVIDER_INVOICES = 'providerInvoices',
  BILLING_PROVISIONS = 'provisions',
  CONTACT = 'contact',
  CONTACT_ALL = 'all',
  CUSTOMER_INVOICES_BANKDRAFTS_DETAIL_FORM = 'customerInvoicesBankdraftsDetailsForm',
  CUSTOMER_INVOICES_BANKDRAFTS_FORM = 'customerInvoicesBankdraftsForm',
  CUSTOMER_INVOICES_DETAIL_FORM = 'customerInvoicesDetailsForm',
  CUSTOMER_INVOICES_GENERAL_DATA_FORM = 'customerInvoicesGeneralDataForm',
  CUSTOMER_INVOICES_PARTIALBANKDRAFT_FORM = 'customerInvoicesPartialbankdraftForm',
  CUSTOM_FIELDS = 'customFields',
  DEFAULT = 'default',
  DOCUMENT = 'document',
  DOCUMENT_INTERVENERS = 'documentInterveners',
  EXPEDIENTS = 'expedients',
  EXPEDIENT_ACTIONS = 'expedientActions',
  EXPEDIENT_AGENDA = 'expedientAgenda',
  EXPEDIENT_ECONOMIC_DATA = 'expedientEconomicData',
  EXPEDIENT_GLOBAL_VISION_PROFITABILITY = 'globalVisionExpProfitability',
  EXPEDIENT_GLOBAL_VISION_TOP_BAR = 'globalVisionTopBar',
  EXPEDIENT_INSURERS = 'expedientInsurers',
  EXPEDIENT_INSURERS_FORM = 'expedientInsurersForm',
  EXPEDIENT_INVOICES = 'expedientInvoices',
  EXPEDIENT_LAST_ACTIONS_WIDGET = 'globalVisionActSummaryList',
  EXPEDIENT_ACTIONS_RESUME_WIDGET = 'globalVisionActSummaryCounters',
  EXPEDIENT_REMINDER_NOTES_WIDGET = 'globalVisionExpNotes',
  EXPEDIENT_SCHEDULE_WIDGET = 'globalVisionScheduleExp',
  EXPEDIENT_SUMMARY_WIDGET = 'globalVisionExpSummary',
  FOLDER = 'folder',
  MAINTENANCE = 'maintenance',
  MAINTENANCE_CUSTOMER_PORTAL = 'maintenanceCustomerPortal',
  MAINTENANCE_USERS = 'maintenanceUsers',
  MAINTENANCE_USERS_CONFIG = 'usersConfiguration',
  MAINTENANCE_USERS_GRID = 'maintenanceUsersGrid',
  MAINTENANCE_USERS_RELATED_FORM = 'maintenanceUsersRelatedForm',
  NUMERATION = 'numeration',
  NUMERATION_FORM = 'numerationForm',
  PERIODIC_BILLING_TAB = 'periodicBillingTab',
  PROFORMA_INVOICES_DETAIL_FORM = 'proformaInvoicesDetailsForm',
  PROFORMA_INVOICES_GENERAL_DATA_FORM = 'proformaInvoicesGeneralDataForm',
  PROFORMA_INVOICES_PAYMENT_METHODS_FORM = 'proformaInvoicesPaymentMethodsForm',
  PROVIDER_INVOICES_BANKDRAFTS_DETAIL_FORM = 'providerInvoicesBankdraftsDetailsForm',
  PROVIDER_INVOICES_BANKDRAFTS_FORM = 'providerInvoicesBankdraftsForm',
  PROVIDER_INVOICES_DETAIL_FORM = 'providerInvoicesDetailsForm',
  PROVIDER_INVOICES_GENERAL_DATA_FORM = 'providerInvoicesGeneralDataForm',
  REPORTS = 'reports',
  REPORTS_LIST = 'reportsList',
  REPORT_DETAIL = 'reportDetail',
  REPOSITORY = 'repository',
  SEARCH = 'search',
  SUB_ACTION = 'subAction',
  TANDEM_FILTERS = 'TandemFilter',
  TEMPLATE = 'template',
  USERS = 'users'
}

export enum ValidationRules {
  REQUIRED = 'required',
  VALIDATION_RULES = 'validationRules'
}
