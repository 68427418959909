<template lang="pug">
extends tabsView.pug
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ContextName, ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import ActionsBarComponent from '@/components/ActionsBar/ActionsBarComponent.vue'
import TabsComponent from '@/components/tabs/TabsComponent/TabsComponent.vue'
import { Icons } from '@/icons/icons'
import { Configuration } from '@/store/modules/configuration/configurationTypes'
import { ActionBarButton, ActionName } from '@/components/ActionsBar/types/ActionBarComponentTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import AlertComponent from '@/components/Alert/AlertComponent.vue'
import { ComponentWhereIsRendered } from '@/store/modules/alerts/alertsTypes'
import LexonBreadcrumbsComponent from '@/components/forms/fields/LexonBreadcrumbs/LexonBreadcrumbsComponent.vue'
import LfHeaderTitleComponent from '@/components/HeaderTitle/LfHeaderTitleComponent.vue'
import { LocaleMessage, LocaleMessages, TranslateResult } from 'vue-i18n'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { entity, Entity } from '@/store/modules/entities/entitiesTypes'
import { VSpacer } from 'vuetify/lib'
import TemplateSelectorComponent from '@/components/template/TemplateSelectorComponent/TemplateSelectorComponent.vue'
import { Tab } from '@/store/modules/tabs/tabsTypes'
import { CustomDialogComponentName, CustomDialogData } from '@/store/modules/dialog/dialogTypes'
import { mixins } from 'vue-class-component'
import PermissionsMixin from '@/mixins/PermissionsMixin.vue'
import { ActionAliasEnum, ActionNewTitleByAlias } from '@/store/modules/actions/actionsTypes'
import { LabelButtonVariant } from '@/components/forms/buttons/types/ButtonTypes'
import { TrackerEvents, trackEvent } from '@/plugins/tracker'

const alertsModule: string = ModuleNamespaces.ALERTS
const authModule: string = ModuleNamespaces.AUTH
const configurationModule: string = ModuleNamespaces.CONFIGURATION
const dialogModule: string = ModuleNamespaces.DIALOG
const endpointModule: string = ModuleNamespaces.ENDPOINT
const entitiesModule: string = ModuleNamespaces.ENTITIES
const expedientsModule: string = ModuleNamespaces.EXPEDIENTS
const formsModule: string = ModuleNamespaces.FORMS
const notificationModule: string = ModuleNamespaces.NOTIFICATIONS
const selectedRegisterModule: string = ModuleNamespaces.SELECTED_REGISTER
const spinnerModule: string = ModuleNamespaces.SPINNER

@Component({
  components: {
    ActionsBarComponent,
    AlertComponent,
    LexonBreadcrumbsComponent,
    LfHeaderTitleComponent,
    TabsComponent,
    TemplateSelectorComponent,
    VSpacer
  }
})
export default class TabsView extends mixins(PermissionsMixin) {
  @Prop({
    type: String
  })
  submenuName!: string

  @Prop({
    type: String
  })
  menuName!: string

  @Prop({
    type: String
  })
  context!: string

  @Prop({
    type: Number
  })
  entityType!: number

  @Prop({
    type: [Number, String]
  })
  entityId!: number | string

  @Prop({
    type: Object
  })
  configuration!: Configuration

  @Prop({
    type: Boolean,
    default: true
  })
  showBreadcrumbs!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
  showTitleHeader!: boolean

  @Action('showDialog', { namespace: dialogModule })
  showDialog: ({}) => {}

  @Action('showCustomDialog', { namespace: dialogModule })
  showCustomDialog: ({}: CustomDialogData) => {}

  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  @Action('fetchCurrentViewConfiguration', { namespace: configurationModule })
  fetchCurrentViewConfiguration: ({}) => Promise<{}>

  @Action('saveRegisterFormData', { namespace: formsModule })
  saveRegisterFormData: ({}) => Promise<void>

  @Action('deleteRegister', { namespace: selectedRegisterModule })
  deleteRegisterAction: ({}) => void

  @Action('saveSelectedIdEntityType', { namespace: entitiesModule })
  saveSelectedIdEntityType: (type: number) => void

  @Action('createEntity', { namespace: entitiesModule })
  createEntity: ({}) => Promise<void>

  @Action('saveSelectedRegisterId', { namespace: selectedRegisterModule })
  saveSelectedRegisterId: ({}) => Promise<void>

  @Action('hideSpinnerLayer', { namespace: spinnerModule })
  hideSpinnerLayerAction: () => Promise<void>

  @Action('sendMailNotification', { namespace: notificationModule })
  sendMailNotification: ({}) => Promise<void>

  @Mutation('SET_DUPLICATE_EXPEDIENT_ID', { namespace: expedientsModule })
  resetDuplicateExpedientId: (status: null) => void

  @Getter('getUserId', { namespace: authModule })
  getUserId: () => any

  @Getter('getCurrentViewTabs', { namespace: configurationModule })
  getCurrentViewTabs: (context: string) => []

  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any

  @Getter('showRelatedForm', { namespace: formsModule })
  showRelatedForm: (context: string) => boolean

  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => any

  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean

  @Getter('getEndpoints', { namespace: endpointModule })
  endpoints: (entityType: number) => Endpoint

  @Getter('getEntity', { namespace: entitiesModule })
  entity: (context: string) => Entity

  @Getter('getDuplicateExpedientId', { namespace: expedientsModule })
  duplicateExpedientId: number

  @Getter('getShowSpinnerLayer', { namespace: spinnerModule })
  showSpinnerLayer: boolean

  @Getter('showAlert', { namespace: alertsModule })
  alertIsVisibleGetter: boolean

  buttonsEnabled = true

  selectedTabInfo = {
    index: 0,
    objectType: ''
  }

  everythingLoaded = false

  formLoaded = false

  showStage = false

  isNewRegister = false

  componentWhereIsRenderedAlertComponent = ComponentWhereIsRendered.TABS_VIEW

  breadcrumbText: TranslateResult | string = `${this.$t('views.selected_register.new')} ${this.entityNameSingular}`

  componentKey = 0

  extraButtons: ActionBarButton[] = []

  showTemplateDialog = false

  get showHeaderTitleKebabMenu(): boolean {
    return !this.isNewRegister
  }

  get getContext(): string {
    return this.context ? this.context : ContextName.DEFAULT
  }

  get getSelectedRegisterData() {
    return this.selectedRegisterData(this.getContext)
  }

  get existSelectedRegister() {
    return !!this.entity(this.getContext).id
  }

  get breadcrumbsText() {
    const entity = this.entity(this.getContext)
    if (ContextName.ACTION === this.getContext) {
      if (this.getSelectedRegisterData) {
        return this.getSelectedRegisterData[entity.keyName] || ActionNewTitleByAlias[entity.alias as ActionAliasEnum]
      }
    } else if (this.getSelectedRegisterData) {
      return this.getSelectedRegisterData[entity.keyName] || this.breadcrumbText
    }
    return ''
  }

  get breadcrumbsItems(): Array<{ text: string | TranslateResult | LocaleMessages; url?: string }> {
    return [
      {
        text: this.parentRouterName,
        url: this.parentRouterUrl
      }
    ]
  }

  get selectedRegisterInfo() {
    let dialogText: LocaleMessage = ''

    switch (this.entity(this.getContext).parent.alias) {
      case entity.contacts.alias:
        dialogText = this.$t('components.dialog.contacts_literal')
        break
      case entity.actions.alias:
        dialogText = this.$t('components.dialog.actions_literal')
        break
    }
    return {
      dialogText
    }
  }

  get buttons(): ActionBarButton[] {
    const { canDelete, canSave } = this.checkEntityPermissionsGetter(this.entityType)
    return [
      ...this.extraButtons,
      {
        icon: Icons.REMOVE,
        tooltip: this.$t('action_buttons.remove'),
        action: ActionName.REMOVE,
        enabled: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true,
        hidden: !canDelete || !this.existSelectedRegister,
        isContextMenuOption: true
      },
      {
        icon: Icons.CLOSE,
        tooltip: this.$t('action_buttons.cancel'),
        class: 'red-color',
        action: ActionName.CLOSE,
        variant: LabelButtonVariant.OUTLINED,
        enabled: this.showRelatedForm(this.getContext) || !this.buttonsEnabled ? false : true
      },
      {
        icon: Icons.CHECK,
        tooltip: this.$t('action_buttons.save'),
        class: 'green-color',
        action: ActionName.SAVE,
        enabled:
          this.showRelatedForm(this.getContext) || !this.checkIfFormIsValid(this.getContext) || !this.buttonsEnabled
            ? false
            : true,
        hidden: !canSave
      }
    ]
  }

  get parentRouterName() {
    if (!this.submenuName) {
      return this.menuName
    }
    return this.submenuName
  }

  get entityNameSingular() {
    return this.parentRouterName.slice(0, -1)
  }

  get parentRouterUrl() {
    if (!this.submenuName) {
      return `/${this.menuName}`
    }
    return `/${this.menuName}/${this.submenuName}`
  }

  get isExpedientsDetailView(): boolean {
    return this.getContext === ContextName.EXPEDIENTS
  }

  get isUsersView(): boolean {
    return this.getContext === ContextName.USERS
  }

  get isMaintenanceView(): boolean {
    return this.getContext === ContextName.MAINTENANCE
  }

  get expedientTitle() {
    return this.isExpedientsDetailView ? this.getSelectedRegisterData.description : null
  }

  get permissionsEntity() {
    return this.entity(this.getContext).type
  }

  created() {
    this.init()
  }

  async init() {
    await this.createEntity({
      entityType: this.entityType,
      idEntity: this.entityId,
      context: this.getContext
    })

    await this.saveSelectedRegisterId({
      id: this.entity(this.getContext).id,
      context: this.getContext
    })

    this.saveSelectedIdEntityType(this.entity(this.getContext).type)

    await this.fetchCurrentViewConfiguration({
      objectType: this.configuration.type,
      alias: this.configuration.alias,
      context: this.getContext
    })

    await this.loadSelectedData()

    // NOTE: proporciona una forma de interactuar desde un componente extendido (templateMethod)
    await this.onInitTabView()

    this.everythingLoaded = true
  }

  async loadSelectedData() {
    if (this.entity(this.getContext).id > 0) {
      await this.fetchSelectedRegisterData({
        endpoint: this.endpoints(this.entity(this.getContext).type).get,
        context: this.getContext
      })
    }
  }

  async onInitTabView() {}

  executeAction(actionName: string) {
    switch (actionName) {
      case ActionName.CLOSE:
        return this.close()
      case ActionName.SAVE:
        return this.save()
      case ActionName.REMOVE:
        this.trackClickRemove()
        return this.remove()
      case ActionName.DUPLICATE_EXPEDIENT:
        return this.duplicateExpedient()
      case ActionName.SEND_EMAIL:
        return this.sendEmailDialog()
    }
    this.executeExtraActions(actionName)
  }

  trackClickRemove() {
    if (this.context === ContextName.ACTION) {
      trackEvent(TrackerEvents.REMOVE_ACTION)
    }
  }

  executeExtraActions(_actionName: string) {}

  close() {
    this.reviewRedirect() !== -1 || this.duplicateExpedientId
      ? this.$router.push({ name: this.parentRouterName })
      : this.$router.go(-1)
    this.resetDuplicateExpedientId(null)
  }

  reviewRedirect() {
    if (window.history.length > 1) {
      return -1
    } else {
      return this.parentRouterName
    }
  }

  onOpenSubForm() {
    this.buttonsEnabled = false
  }

  onCloseSubForm() {
    this.buttonsEnabled = true
  }

  onRenderForm(_tabIndex: number, _contextName: string) {
    if (this.showSpinnerLayer) {
      this.hideSpinnerLayerAction()
    }
  }

  changeSelectedTabIndex(tabIndex: number, objectType: string) {
    this.selectedTabInfo = {
      index: tabIndex,
      objectType
    }
  }

  async onInitForm(params: any) {
    const { isNewForm } = params
    this.isNewRegister = isNewForm
    this.formLoaded = true
  }

  onCreatedForm(_params: any) {}

  onBeforeSetComponentProperties(_component: any, _tab: any, _tabIndex: number) {}

  onFieldChanged(_schema: [], _formData: object, _field: object, _value: any) {}

  beforeSetInitialTab() {}

  async save(redirectToGridTable = true, resolve: any = null, _idObjectType = '') {
    const { type } = this.entity(this.getContext)
    const { canSave } = this.checkEntityPermissionsGetter(type)
    if (canSave) {
      await this.saveRegisterFormData({
        endpoint: this.endpoints(this.entity(this.getContext).type).save,
        idSelectedRegister: this.entity(this.getContext).id,
        goToURL: redirectToGridTable ? this.reviewRedirect() : null,
        context: this.getContext
      })
    }

    if (resolve) {
      resolve()
    }
  }

  async removeRegister() {
    const { dialogText } = this.selectedRegisterInfo

    this.deleteRegisterAction({
      deleteEndpoint: this.endpoints(this.entity(this.getContext).type).delete,
      listEndpoint: this.endpoints(this.entity(this.getContext).type).list,
      goToURL: this.reviewRedirect(),
      name: this.getSelectedRegisterData[this.entity(this.getContext).keyName],
      dialogText
    })
  }

  remove() {
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.remove_register_text', {
        text: this.selectedRegisterInfo.dialogText,
        register: this.getSelectedRegisterData[this.entity(this.getContext).keyName]
      }),
      mainButtonText: this.$t('action_buttons.remove'),
      secondaryButtonText: this.$t('action_buttons.cancel'),
      action: this.removeRegister
    })
  }

  duplicateExpedient() {
    this.showCustomDialog({
      name: CustomDialogComponentName.DUPLICATE_EXPEDIENT,
      props: {
        id: this.selectedRegisterId(this.context),
        name: this.getSelectedRegisterData.description
      }
    })
  }

  async sendEmailDialog() {
    this.save(false)
    this.showDialog({
      type: DialogTypes.INFO,
      message: this.$t('components.dialog.send_invoce_email'),
      mainButtonText: this.$t('action_buttons.send_notification'),
      action: this.sendEmail
    })
  }

  async sendEmail() {
    const { idCustomer, idOwnFile } = this.selectedRegisterData(this.getContext)
    await this.sendMailNotification({
      templateType: this.getContext,
      notificationData: {
        idCustomer,
        refExpedient: idOwnFile
      }
    })
  }

  changeBreadcrumbs(_value: boolean) {}

  onExpedientGlobalVisionMounted(): void {}

  closeTemplateDialog(): void {
    this.showTemplateDialog = false
  }

  async generateTemplateDialog(_templateSelected: any) {}

  onBeforeSaveTabsVisibility(_curentTab: Tab) {}
  onAfterSaveTabsVisibility(_curentTab: Tab) {}
}
</script>

<style lang="scss" scoped>
.selected-register-container {
  display: none;

  &.everything-loaded {
    display: block;
  }

  ::v-deep .v-icon {
    color: $corporate-color;
  }

  .header {
    @include flex($flex-direction: column, $align-items: flex-start);
    position: relative;

    ~ .tabs-container {
      margin-top: 24px;
    }

    &.alert-is-visible {
      position: sticky;
      top: 63px;
      z-index: 9999;
    }
  }
}

@include mobile {
  .header {
    flex-wrap: wrap;
  }
}
</style>
