import { Ref, ComputedRef } from 'vue';
import { GridComponent as EjsGrid } from '@syncfusion/ej2-vue-grids'
import { capitalizeFirstLetter } from '@/helpers/strings'

interface PropsUseGridMethods {
  gridRef: Ref<InstanceType<typeof EjsGrid> | null> | ComputedRef<InstanceType<typeof EjsGrid> | null>
  exportName?: string
}

interface ResponseUseGridMethods {
  getSelectedRecords: () => any[]
  openColumnChooser: (x: number, y: number) => void
  excelExport: () => void
  refreshColumns: () => void
}

export default function useGridMethods(props: PropsUseGridMethods): ResponseUseGridMethods {
  const { gridRef, exportName = 'export' } = props

  const excelExport = () => {
    if (gridRef.value) {
      const fileName = `${capitalizeFirstLetter(exportName)}.xlsx`
      const excelExportProperties = {
        fileName
      }
        ; (gridRef.value as any).excelExport(excelExportProperties)
    }
  }

  const openColumnChooser = (x: number, y: number) => {
    if (gridRef.value) {
      ; (gridRef.value as any).openColumnChooser(x, y)
    }
  }

  const getSelectedRecords = () => {
    if (gridRef.value) {
      const records = (gridRef.value as any).getSelectedRecords()
      return records
    }
  }

  const refreshColumns = () => {
    if (gridRef.value) {
      setTimeout(() => {
        (gridRef.value as any).refreshColumns()
      })
    }
  }

  return {
    getSelectedRecords,
    openColumnChooser,
    refreshColumns,
    excelExport
  }
}
