import { MainService } from '@/services/MainService'
import { endpoints } from '@/store/modules/endpoint/endpointTypes'
import store from '@/store/store'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { AxiosError } from 'axios'
import { computed, ComputedRef } from 'vue'
import { URLS } from '@/router/routes/urlRoutes'

interface PropsUseActionClassification {
  parentContext: string
}

interface ResponseUseActionClassification {
  outerRouteNames: any
  isSubAction: ComputedRef<boolean>
  actionClassification: ComputedRef<any[]>
  currentAction: ComputedRef<any>
  read: () => void
  save: (formData: any) => any
  remove: (id: string) => Promise<{success: boolean, message?: string}>
}

export default function useActionClassification(props: PropsUseActionClassification): ResponseUseActionClassification {

  const { parentContext } = props

  const currentAction = computed(() => store.getters[`${ModuleNamespaces.SELECTED_REGISTER}/getSelectedRegisterData`](parentContext))

  const read = async () => {
    await store.dispatch(`${ModuleNamespaces.ACTIONS}/fetchActionRelations`, { selectedRegisterId: currentAction.value.id })
  }

  const save = async (formData: any) => {
    try {
      const { idRelation, idActionType } = formData
      const data = {
        ...formData,
        idRelation: idRelation,
        idAction: currentAction.value.id,
        idActionRelationType: idActionType
      }
      const response = await new MainService().postData(endpoints.actionsRelations.save, data)
      return {success: Boolean(response.data.id)}
    } catch (error) {
      const { response } = error as AxiosError
      const { data } = response as any
      return { success: false, message: data?.detail || data?.exceptionDesc}
    }
  }

  const remove = async (id: string) => await store.dispatch(`${ModuleNamespaces.ACTIONS}/removeActionClassification`, id)

  const actionClassification = computed(() => store.getters[`${ModuleNamespaces.LIST_ITEMS}/getListItemsWithFormattedDates`])

  const isSubAction: ComputedRef<boolean> = computed(() => Boolean(currentAction.value.parentId))

  const outerRouteNames = {
    EXP: `${URLS.EXPEDIENTS}-${URLS.EXPEDIENTS}`,
    contact: `${URLS.CONTACTS}-${URLS.CONTACT}`
  }

  return {
    outerRouteNames,
    isSubAction,
    actionClassification,
    currentAction,
    read,
    save,
    remove
  }
}
