<template lang="pug">

div(:class="['lf-schedule', { 'sync': calendarProvider }]")

  SpinnerLayerComponent(
    v-if="showGeneralSpinner"
    class="general-spinner"
  )

  template(v-if="calendarProvider")
    span(:class="[icons.info, 'info-icon', 'lf-icon__md']")
    div(class="lf-schedule-text" v-if="calendarProvider")
      span(class="lf-schedule-title") {{ texts.calendars }}
      span(class="lf-calendar-description")  {{ texts.description }}
      button(
        class="lf-calendar-link"
        v-show="!hasAppointment && showNewButton && calendarProvider"
        @click.stop="onCreateEvent") {{ texts.newEvent }}

  template(v-else)
    span(:class="[icons.warning, 'warning-icon', 'lf-icon__md']")
    div(class="lf-schedule-text")
      span(class="lf-schedule-title") {{ texts.calendarSync }}
      span(class="lf-calendar-description") {{ texts.calendarSyncText }}
      div(class="lf-schedule-links")
        span(
          class="lf-calendar-link"
          v-show="!hasAppointment && !calendarProvider"
          @click.stop="selectCalendarProvider") {{ texts.configAccount }}
        span(
          class="lf-calendar-link"
          v-show="showRefresh && !hasAppointment && !calendarProvider"
          @click.stop="refreshScreen") {{ texts.refreshScreen }}

  div(v-show="hasAppointment" class="lf-schedule-event-info")
    div(class="lf-schedule-event-info-header"
      :style="{ backgroundColor: appointment.color}"
    ) {{ appointment.subject}}
    div(class="lf-schedule-event-info-content"
      :style="{ borderColor: appointment.color}")
      div(class="lf-schedule-event-col")
        span(:class="[icons.calendarCheck,'lf-sch-icon']")
        span() {{ appointment.startDate }}
      div(class="lf-schedule-event-col")
        span(:class="[icons.meeting,'lf-sch-icon']")
        span() {{ appointment.calendarName }}
      div(v-if="appointment.typeEvent" class="lf-schedule-event-col")
        span(:class="[icons.circle,'lf-sch-icon']" :style="{color: appointment.typeEventColor}")
        span() {{ appointment.typeEvent }}
      div(class="lf-schedule-event-col lf-action-text")
        span(:class="[icons.visible,'lf-sch-icon']")
        span(@click.stop="editEvent()") {{ texts.moreDetails }}

  div(v-if="showDialog" class="dialog-container" @click.stop="checkIfHideDialog")
    div(class="dialog-message-container")
      LfBlueTitleComponent(class="blue-title" :title="labelDialog" showClose @close="hideDialog")
      div(v-show="showSpinner" class="lf-dialog-spinner")
        SpinnerLayerComponent(class="spinner-layer")
      iframe(
        :src="url"
        frameborder="0"
        allowfullscreen)

</template>

<script lang="ts">
import { Icons } from '@/icons/icons'
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ModuleNamespaces } from '@/store/types/storeGlobalTypes'
import { changeDateFormat } from '@/helpers/dateTime'
import { Endpoint } from '@/store/modules/endpoint/endpointTypes'
import { Entity } from '@/store/modules/entities/entitiesTypes'
import { ActionFields } from '@/views/actions/Actions/types/ActionDetailViewTypes'
import { DialogTypes } from '@/store/modules/dialog/dialogTypes'
import { URLS } from '@/router/routes/urlRoutes'
import LfBlueTitleComponent from '@/components/HeaderTitle/LfBlueTitleComponent.vue'
import SpinnerLayerComponent from '@/components/Spinner/SpinnerLayerComponent.vue'

const actionsModule = ModuleNamespaces.ACTIONS
const appointmetsModule = ModuleNamespaces.APPOINTMENTS
const authModule = ModuleNamespaces.AUTH
const dialogModule = ModuleNamespaces.DIALOG
const endpointModule = ModuleNamespaces.ENDPOINT
const entitiesModule = ModuleNamespaces.ENTITIES
const expedientsModule = ModuleNamespaces.EXPEDIENTS
const formsModule = ModuleNamespaces.FORMS
const lexboxModule = ModuleNamespaces.LEXBOX
const selectedRegisterModule = ModuleNamespaces.SELECTED_REGISTER

@Component({
  components: {
    SpinnerLayerComponent,
    LfBlueTitleComponent
  }
})
export default class LexonScheduleComponent extends Vue {
  @Prop({
    type: Object
  })
  value!: object

  @Prop({
    type: String,
    default: () => ({})
  })
  context!: string

  @Action('fetchCalendarServiceUrl', { namespace: lexboxModule })
  fetchServiceUrl: (calendarQuery: object) => void
  @Action('saveRegisterFormData', { namespace: formsModule }) saveRegisterFormData: ({}) => void
  @Action('prepareRegisterFormDataToSave', { namespace: formsModule }) prepareFormData: ({}) => void
  @Action('deleteAppointment', { namespace: appointmetsModule }) deleteAppointment: (id: string) => void
  @Action('saveEndpointsByEntity', { namespace: endpointModule }) saveEndpointsByEntity: (entity: Entity) => void
  @Action('fetchCalendarProvider', { namespace: lexboxModule }) fetchCalendarProvider: () => void
  @Action('showDialog', { namespace: dialogModule }) showDialogInfo: ({}) => {}
  @Action('fetchAccessToken', { namespace: lexboxModule }) fetchAccessToken: (calendarQuery: object) => void
  @Action('fetchSelectedRegisterData', { namespace: selectedRegisterModule })
  fetchSelectedRegisterData: ({}) => Promise<{}>

  @Mutation('RESET_EVENT_CALENDAR_SERVICE_URL', { namespace: lexboxModule }) resetEventCalendar: () => void
  @Mutation('RESET_CALENDAR_PROVIDER', { namespace: lexboxModule }) resetCalendarProvider: () => void

  @Getter('getEventCalendarServiceUrl', { namespace: lexboxModule }) eventServiceUrl: string
  @Getter('checkIfFormIsValid', { namespace: formsModule })
  checkIfFormIsValid: (context: string) => boolean
  @Getter('getSelectedActionType', { namespace: actionsModule }) selectedActionType: string
  @Getter('getSelectedRegisterId', { namespace: selectedRegisterModule })
  selectedRegisterId: (context: string) => number
  @Getter('getEntity', { namespace: entitiesModule }) entity: (context: string) => Entity
  @Getter('getEndpoints', { namespace: endpointModule }) endpoints: (entityType: number) => Endpoint
  @Getter('getRegisterFormData', { namespace: formsModule }) getFormData: () => object
  @Getter('getExpedientStageStateForm', { namespace: expedientsModule }) expedientStageStateForm: string
  @Getter('getCalendarProvider', { namespace: lexboxModule }) calendarProvider: string
  @Getter('getProUserId', { namespace: authModule }) idProUser: string
  @Getter('getUserName', { namespace: authModule }) userName: string
  @Getter('getNavisionId', { namespace: authModule }) navisionId: number
  @Getter('getUserId', { namespace: authModule }) userId: number
  @Getter('getAccessToken', { namespace: lexboxModule }) accessToken: string
  @Getter('getCompanyId', { namespace: authModule }) selectedCompanyId: number
  @Getter('getSelectedRegisterData', { namespace: selectedRegisterModule })
  selectedRegisterData: (context: string) => any

  showDialog: boolean = false
  labelDialog = ''

  icons = {
    close: Icons.CLOSE,
    calendarCheck: Icons.CALENDAR_CHECK,
    meeting: Icons.MEETING,
    circle: Icons.CIRCLE_FULL,
    visible: Icons.VISIBLE,
    refresh: Icons.REFRESH,
    warning: Icons.WARNING_FULL,
    info: Icons.INFO_FULL
  }

  texts = {
    allDay: this.$t('components.schedule.full_day'),
    calendarSync: this.$t('components.calendar.calendar_sync'),
    calendarSyncText: this.$t('components.calendar.calendar_sync_text'),
    calendars: this.$t('components.schedule.calendars'),
    configAccount: this.$t('components.calendar.config_account'),
    description: this.$t('components.schedule.description'),
    editEvent: this.$t('components.schedule.edit_event'),
    moreDetails: this.$t('components.schedule.more_details'),
    newEvent: this.$t('components.schedule.new_event'),
    refreshScreen: this.$t('components.calendar.refresh_screen'),
    update: this.$t('components.schedule.update'),
    accept: this.$t('action_buttons.accept'),
    goToAgenda: this.$t('components.calendar.go_to_diary')
  }

  url: string = ''

  hasAppointment = false
  isNewEvent = false
  showNewButton = true
  appointment = {
    subject: '',
    account: '',
    startDate: '',
    typeEvent: '',
    typeEventColor: '',
    calendarName: '',
    color: '',
    provider: '',
    idCalendar: '',
    fullDay: '0',
    iCalUId: ''
  }

  showSpinner = true
  showRefresh = false
  showGeneralSpinner = true

  actionEndpoint: Endpoint

  actualIdEvent: number | null = null
  actualId: string | null = null

  @Watch('eventServiceUrl')
  getUrl(value: string) {
    this.url = value
    if (value) {
      this.showSpinner = false
    }
  }

  get selectedActiontId(): number {
    return this.selectedRegisterId(this.context)
  }

  async init() {
    if (this.accessToken === '') {
      const accessParams = {
        idClienteNavision: this.idProUser,
        idCompany: this.selectedCompanyId
      }
      await this.fetchAccessToken(accessParams)
    }
    if ((this as any).value && typeof (this as any).value.provider !== 'undefined') {
      this.parseAppointment(this.value)
      this.appointment.provider = (this as any).value.provider.toUpperCase()
      this.hasAppointment = true
      this.actualIdEvent = (this as any).value.idEvent
      this.actualId = (this as any).value.id
    }
    if (!this.calendarProvider) {
      await this.fetchCalendarProvider()
    }
    window.addEventListener('message', this.eventReceived)
  }

  async created() {
    await this.init()
    this.showGeneralSpinner = false
  }

  eventReceived = (event: any) => {
    try {
      const data = JSON.parse(event.data)
      if (event.data && data.actionCancelled) {
        if (data.removed === true) {
          this.deleteEvent()
        } else if (data.message === 'Not Found') {
          this.showDialogInfo({
            type: DialogTypes.INFO,
            message: this.$t('components.dialog.event_not_found_in_calendar'),
            action: this.deleteEvent
          })
        } else if (data.message === 'Not Account') {
          this.showDialogInfo({
            type: DialogTypes.INFO,
            message: this.$t('components.dialog.event_not_account'),
            mainButtonText: this.texts.goToAgenda,
            secondaryButtonText: this.texts.accept,
            action: () => {
              this.openAgenda()
            }
          })
        } else if (data.message === 'Not Permission') {
          this.showDialogInfo({
            type: DialogTypes.ERROR,
            message: this.$t('components.dialog.event_not_permission')
          })
        }
        this.hideDialog()
      }
    } catch (error) {}
  }

  async deleteEvent() {
    await this.deleteAppointment((this as any).actualId)
    this.hideDialog()
  }

  destroyed() {
    window.removeEventListener('message', this.eventReceived)
    this.resetEventCalendar()
  }

  async reloadData() {
    await this.fetchSelectedRegisterData({
      endpoint: this.endpoints(this.entity(this.context).type).get,
      context: this.context
    })
    const data = this.selectedRegisterData(this.context)
    if (data.appointment) {
      this.actualIdEvent = data.appointment.idEvent
      this.actualId = data.appointment.id
      this.parseAppointment(data.appointment)
    } else {
      this.resetEventCalendar()
      this.showNewButton = true
    }
    this.hasAppointment = data.appointment ? true : false
  }

  parseAppointment(value: any) {
    ;(this as any).appointment = { ...value }
    this.appointment.startDate = changeDateFormat((this as any).appointment.startDate, '', 'DD/MM/YYYY HH:mm')
    if (this.appointment.fullDay === '1') {
      this.appointment.startDate = this.appointment.startDate.split(' ')[0] + ` (${this.texts.allDay})`
    }
  }

  selectCalendarProvider() {
    window.open(`${process.env.VUE_APP_LEXBOX_ACCESS}${this.accessToken}`, '_blank')
    this.showRefresh = true
    this.resetCalendarProvider()
  }

  refreshScreen() {
    window.location.reload()
  }

  openAgenda() {
    const routeData = this.$router.resolve({ name: `${URLS.AGENDA}` })
    window.open(routeData.href, '_blank')
  }

  showRequiredFormFieldsAlert() {
    this.showDialogInfo({
      type: DialogTypes.WARNING,
      message: this.$t('components.alerts.required_fields'),
      hideSecondaryButton: true
    })
  }

  onCreateEvent() {
    this.checkIfFormIsValid(this.context) ? this.newEvent() : this.showRequiredFormFieldsAlert()
  }

  async newEvent() {
    this.showNewButton = false
    if (this.selectedRegisterId(this.context) === 0) {
      this.saveEndpointsByEntity(this.entity(this.context))
      this.regularizeFieldsOnSave(this.selectedActionType)
      this.appointment.subject = (this as any).getFormData.subject
      await this.$nextTick()
      await this.saveRegisterFormData({
        endpoint: this.endpoints(this.entity(this.context).type).save,
        context: this.context
      })

      this.$emit('input', {})
    }
    ;(this as any).labelDialog = this.texts.newEvent
    this.isNewEvent = true
    this.loadServiceUrl()
    this.openDialog()
  }

  regularizeFieldsOnSave(actionType: string) {
    const formData: any = this.getFormData

    const rate = formData[ActionFields.RATE_ID]
    if (rate && rate.id) {
      formData[ActionFields.RATE_ID] = rate.id
    }

    if (typeof formData[ActionFields.ACTION_TYPE] === 'undefined') {
      formData[ActionFields.ACTION_TYPE] = actionType
    }

    this.prepareFormData(formData)
  }

  async editEvent() {
    ;(this as any).labelDialog = this.texts.editEvent
    this.isNewEvent = false
    this.loadServiceUrl()
    this.openDialog()
  }

  loadServiceUrl() {
    const calendarParams = {
      query: {
        idActuation: this.selectedActiontId,
        idEvent: this.hasAppointment ? this.actualIdEvent : null,
        startDate: this.hasAppointment ? (this as any).appointment.startDate : null,
        endDate: this.hasAppointment ? (this as any).appointment.endDate : null,
        idCalendar: this.hasAppointment ? this.appointment.idCalendar : null,
        calendarName: this.hasAppointment ? this.appointment.calendarName : null,
        account: this.hasAppointment ? this.appointment.account : null,
        iCalUId: this.hasAppointment ? this.appointment.iCalUId : null,
        title: this.isNewEvent ? (this as any).getFormData.subject || this.appointment.subject : null,
        idClienteLef: this.navisionId,
        idUser: (this as any).getFormData.userId,
        idUserApp: this.userId,
        name: this.userName
      },
      provider: this.appointment.provider
    }

    this.fetchServiceUrl(calendarParams)
  }

  openDialog() {
    this.showDialog = true
  }

  async hideDialog() {
    this.showDialog = false
    this.showSpinner = true
    this.url = ''
    await this.reloadData()
    this.regularizeFieldsOnSave(this.selectedActionType)
  }

  checkIfHideDialog($event: Event) {
    const elementClicked = $event.target as HTMLElement

    if (elementClicked.classList.contains('dialog-container')) {
      this.hideDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-schedule {
  display: flex;
  position: relative;
  padding: $spacing-xs $spacing-sm;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: $spacing-xs;
  border-radius: $cornerRadius-sm;
  background: $tertiary-saffron-050;
  margin-bottom: $spacing-xxl;

  &.sync {
    background: $tertiary-byzantineBlue-050;
  }

  .warning-icon {
    color: $tertiary-saffron-200;
  }

  .info-icon {
    color: $tertiary-byzantineBlue-200;
  }

  .general-spinner {
    --spinner-layer-min-height: 65px;
    --spinner-layer-position: absolute;
    left: 0;
    top: 0;
  }

  .lf-schedule-text {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    .lf-schedule-title {
      @include milano-bold-16;
      color: $neutral-grey-800;
    }

    .lf-calendar-description {
      @include milano-regular-14;
      color: $neutral-grey-800;
    }

    .lf-calendar-link {
      @include milano-medium-14;
      color: $tertiary-byzantineBlue-200;
      text-align: center;
      width: 115px;
      text-align: left;
      cursor: pointer;
      margin-right: $spacing-md;
    }

    .lf-default-calendar-refresh {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .lf-default-calendar-refresh-icon {
        font-size: 20px;
        margin-right: 8px;
      }

      .lf-default-calendar-refresh-text {
        @include milano-regular-14;
        color: $neutral-grey-800;
      }
    }

    .lf-schedule-icon {
      position: relative;
      height: 30px;
      width: 30px;
      margin: 10px 10px 40px 0px;
      float: left;
    }
  }

  @media (min-width: 615px) {
    .lf-schedule-text {
      .lf-schedule-icon {
        margin-bottom: 30px;
      }
    }
  }

  @media (min-width: 850px) {
    .lf-schedule-text {
      .lf-schedule-icon {
        margin-bottom: 10px;
      }
    }
  }

  .lf-schedule-event-info {
    font-family: $corporate-font;
    width: 85%;
    margin-bottom: 20px;
    .lf-schedule-event-info-header {
      background: #741425;
      color: $white-01;
      padding: 13px 10px;
    }
    .lf-schedule-event-info-content {
      @include flex;
      justify-content: flex-start;
      border: 2px solid #741425;
      .lf-schedule-event-col {
        padding: 10px;
        .lf-sch-icon {
          margin-right: 10px;
        }
      }
      .lf-action-text {
        color: $blue-01;
        font-family: $corporate-font-bold;
        cursor: pointer;
      }
    }
  }
}
.dialog-container {
  @include flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;

  .dialog-message-container {
    @include flex($flex-direction: column);
    position: absolute;
    background-color: $white-01;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    margin: 0.5rem;
    max-width: 700px;

    .blue-title {
      margin: $spacing-xl;
    }

    iframe {
      width: 698px;
      height: 600px;
    }
  }
  .lf-dialog-spinner {
    margin-top: 10%;
    position: absolute;
  }
}
</style>
