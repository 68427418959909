import {
  ColumnChooserSettingsModel,
  ContextMenuItemModel,
  FilterSettings,
  SearchSettings,
  SelectionSettings
} from '@syncfusion/ej2-vue-grids'
import { ToolbarItemModel } from '@syncfusion/ej2-vue-filemanager'
import { ClickEventArgs, CommandClickEventArgs } from './LfGridTypes'
import { PropType } from 'vue'
import { filterSettingsTypesOperator } from '../GridTable/types/GridTableTypes'

export const gridProps = {
  itemData: {
    type: [Array, Object],
    required: true
  },
  contextMenuItems: {
    type: Array as PropType<ContextMenuItemModel[]>,
    default: () => []
  },
  toolbarOptions: {
    type: Array as PropType<ToolbarItemModel[]> | null,
    default: null
  },
  selectionOptions: {
    type: Object as PropType<SelectionSettings>,
    default: () => ({ mode: 'Row', type: 'Single' })
  },
  toolbarClick: {
    type: Function as PropType<(args: ClickEventArgs) => void>,
    default: () => {}
  },
  commandClick: {
    type: Function as PropType<(args: CommandClickEventArgs) => void>,
    default: () => {}
  },
  allowPaging: {
    type: Boolean,
    default: true
  },
  allowSorting: {
    type: Boolean,
    default: true
  },
  allowFiltering: {
    type: Boolean,
    default: true
  },
  filterSettings: {
    type: Object as PropType<FilterSettings>,
    default: () => ({
      type: 'Menu',
      ignoreAccent: true,
      columns: [],
      operators: filterSettingsTypesOperator
    })
  },
  searchSettings: {
    type: Object as PropType<SearchSettings>,
    default: () => ({
      ignoreCase: true,
      ignoreAccent: true
    })
  },
  columnChooserSettings: {
    type: Object as PropType<ColumnChooserSettingsModel>,
    default: () => ({
      ignoreAccent: true,
      operator: 'contains'
    })
  },
  showColumnChooser: {
    type: Boolean,
    default: true
  },
  chipsFilter: {
    type: Boolean,
    default: false
  },
  childGrid: {
    type: Object,
    default: null
  },
  rowDataBound: {
    type: Function as PropType<(...args: any[]) => void>,
    default: (_args: any[]) => {}
  },
  exportName: {
    type: String,
    default: 'Export'
  },
  rememberUserConfig: {
    type: Boolean,
    default: false
  }
}
