export interface SubAction {
  id: string
  subject: string
  parentId: string
  appointmentId: string | null
  typeId: ActionTypes
  type: string
  startEPOCH: string | null
  endEPOCH: string | null
  expirationDateEPOCH: string | null
  state: string | null
  idExpedient: string | null
}

export interface File {
  canEdit: number
  fileId: number
  idOwnFile: string
}

export enum ActionTypes {
  ALL = 'ALL',
  CALLS = 'LLAM',
  EMAILS = 'EMAIL',
  MEETINGS = 'REUN',
  LEXNET = 'LEXN',
  OTHERS = 'OTRA',
  PROCEDURES = 'TRAM'
}

export interface SubActionsState {
  subAction: object | null
  parentAction: object | null
  subActionlist: ListResult | null,
  actionlist: ListResult | null,
  selectedSubActionType: string
  chronometerNewActionInfo: ChronometerNewActionInfo
}

export interface ListResult {
  data: object[]
  pagination: ListPagination
}

export interface ListPagination {
  numPage: number
  totalRecs: number
}

export interface ChronometerNewActionInfo {
  start: string
  end: string
  duration: number
}

export const subActionsMutationTypes = {
  FETCH_SUB_ACTION: 'FETCH_SUB_ACTION',
  FETCH_SUB_ACTION_LIST: 'FETCH_SUB_ACTION_LIST',
  FETCH_PARENT_ACTION: 'FETCH_PARENT_ACTION',
  FETCH_ACTION_LIST: 'FETCH_ACTION_LIST',
  RESET_SUB_ACTION_LIST: 'RESET_SUB_ACTION_LIST',
  RESET_ACTION_LIST: 'RESET_ACTION_LIST',
  RESET_CHRONOMETER_NEW_ACTION_INFO: 'RESET_CHRONOMETER_NEW_ACTION_INFO',
  SAVE_SELECTED_ACTION_TYPE: 'SAVE_SELECTED_ACTION_TYPE',
  SET_CHRONOMETER_NEW_ACTION_INFO: 'SET_CHRONOMETER_NEW_ACTION_INFO',
  RESET_SUB_ACTION: 'RESET_SUB_ACTION'
}

export enum SUBACTION_ERRORS {
  RESPONSE_ERROR = 'No se puede asociar la actuación porque es una subactuación'
}
