<template>
  <div v-if="toasts.length > 0" class="toast-wrapper">
    <transition-group name="toast-animation" tag="div">
      <LFToastComponent v-for="toast in toasts" :key="toast.id" :toast="toast" />
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import LFToastComponent from './LFToastComponent.vue'
import useToasts from '@/components/Toasts/composables/useToasts'

// COMPOSABLES
const { toasts } = useToasts()
</script>

<style scoped lang="scss">
.toast-wrapper {
  position: absolute;
  top: 69px;
  right: 32px;
  z-index: 9999;

  .toast-animation-enter-active,
  .toast-animation-leave-active {
    transition: opacity 0.4s;
  }
  .toast-animation-enter-from,
  .toast-animation-leave-to {
    opacity: 0;
  }
}
</style>
